import React from "react";
import { Link } from "react-router-dom";

function HomeItem(props) {
  const { path, title, image } = props;

  return (
    <div
      className="h-1/2 w-full md:h-screen bg-cover bg-center text-white flex"
      style={{ backgroundImage: "url(" + image + ")" }}
    >
      <div className="w-full h-full  backdrop-brightness-50 bg-black/60 hover:bg-black/20 cursor-pointer">
        <Link to={path}>
          <div className="w-full h-full md:h-screen flex items-center justify-center">
            <span className="2xl:text-5xl text-4xl self-center justify-center">
              {title}
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default HomeItem;
