import React from "react";
import useFetchData from "../../hooks/useFetchData";
import AddOnsItem from "./AddOnsItem/AddOnsItem";
import Spinner from "../../components/Spinner/Spinner";
import { useSelector } from "react-redux";

function AddOns() {
  const { loading } = useFetchData(`/hotel/active`);
  const { options } = useSelector((state) => state.options);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="border bg-white rounded p-5">
          <div className="grid sm:grid-cols-2 justify-center gap-10 md:gap-5 xl:grid-cols-3 pb-8 ">
            {options?.map((item, i) => (
              <AddOnsItem item={item} key={i} i={i} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default AddOns;
