import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalAdultsTable: 0,
  totalNights: 0,
  totalAmountToBlock: 0,
  mode: 0,
  errorMsg: "",
  totalAdults: 2,
  totalPrice: 0,
  showDates: false,
  startDate: "",
  endDate: "",
  roomIndex: 0,
  orderId: "",
  email: "",
  giftVoucher: {},
  reservationType: "",
  country: "",
  rooms: [
    {
      name: "",
      room_type: "",
      date_from: "",
      date_to: "",
      adults: 2,
      children: 0,
      comments: "",
      room_total_price: 0,
      prices: [
        {
          date: "",
          price: 0,
        },
      ],
      options: [],
    },
  ],
};

export const bookingParamSlice = createSlice({
  name: "bookingParam",
  initialState,
  reducers: {
    setTotalGuests: (state, action) => {
      state.totalGuests = action.payload;
    },
    setTotalAdultsTable: (state, action) => {
      state.totalAdultsTable = action.payload;
    },
    setTotalAdults: (state, action) => {
      state.totalAdults = action.payload;
    },
    setTotalNights: (state, action) => {
      state.totalNights = action.payload;
    },
    setTotalAmountToBlock: (state, action) => {
      state.totalAmountToBlock = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload;
    },

    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setTotalPrice: (state, action) => {
      state.totalPrice = action.payload;
    },
    setShowDates: (state, action) => {
      state.showDates = action.payload;
    },
    setReservationType: (state, action) => {
      state.reservationType = action.payload;
    },
    setGiftVoucher: (state, action) => {
      state.giftVoucher = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setRoomIndex: (state, action) => {
      state.roomIndex = action.payload;
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    incrementGuest: (state, action) => {
      state.rooms[action.payload].adults++;
      state.totalAdults++;
    },
    decrementGuest: (state, action) => {
      state.rooms[action.payload].adults--;
      state.totalAdults--;
    },
    incrementChildren: (state, action) => {
      state.rooms[action.payload].children++;
    },
    decrementChildren: (state, action) => {
      state.rooms[action.payload].children--;
    },
    addRoom: (state, action) => {
      state.rooms.push(action.payload);
      state.totalAdults = state.totalAdults + 2;
    },
    removeRoom: (state, action) => {
      state.totalAdults =
        state.totalAdults - state.rooms[action.payload].adults;
      state.rooms.splice(action.payload, 1);
    },
    resetBasketState: (state, action) => {
      return {
        ...state,
        rooms: [
          {
            name: "",
            date_from: state.startDate,
            date_to: state.endDate,
            room_type: "",
            adults: state.totalAdults,
            children: 0,
            comments: "",
            room_total_price: 0,
            prices: [
              {
                date: "",
                price: 0,
              },
            ],
            options: [],
          },
        ],
      };
    },
    resetState: (state = initialState, action) => {
      return initialState;
    },

    addRoomToBasket: (state, action) => {
      return {
        ...state,
        rooms: state.rooms.map((room, index) => {
          if (index === action.payload.roomIndex) {
            return {
              ...room,
              name: action.payload.name,
              room_type: action.payload.code,
              room_total_price: action.payload.total_price,
              night_price: action.payload.night_price,
              date_from: state.startDate,
              date_to: state.endDate,
              prices: action.payload.prices,
            };
          } else {
            return room;
          }
        }),
      };
    },

    addOptionsToBasket: (state, action) => {
      const itemInBasket = state.rooms[state.roomIndex].options.find(
        (item) => item.code === action.payload.code
      );

      if (itemInBasket) {
        itemInBasket.quantity = itemInBasket.quantity + action.payload.quantity;
      } else {
        state.rooms[state.roomIndex].options.push({
          ...action.payload,
        });
      }
    },
    removeOption: (state, action) => {
      let room = state.rooms.find((_, index) => index === action.payload.index);

      let optionIndex = room.options.findIndex(
        (option) => option.code === action.payload.code
      );

      let tempRooms = state.rooms;

      tempRooms[action.payload.index].options.splice(optionIndex, 1);
    },
  },
});

export const bookingParamReducer = bookingParamSlice.reducer;

export const {
  setShowDates,
  setStartDate,
  setEndDate,
  incrementGuest,
  decrementGuest,
  incrementChildren,
  decrementChildren,
  addRoom,
  removeRoom,
  resetState,
  addRoomToBasket,
  setRoomIndex,
  resetBasketState,
  addOptionsToBasket,
  setOrderId,
  setEmail,
  removeOption,
  setGiftVoucher,
  setTotalPrice,
  setReservationType,
  setCountry,
  setErrorMsg,
  setMode,
  setTotalAmountToBlock,
  setTotalNights,
  setTotalAdults,
  setTotalAdultsTable,
} = bookingParamSlice.actions;
