import React, { useEffect, useState } from "react";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import {
  Datepicker,
  setOptions,
  localeFr,
  localeEn,
  localeDe,
} from "@mobiscroll/react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { momentTimezone } from "@mobiscroll/react";
momentTimezone.moment = moment;

function HotelCalendar(props) {
  const selectedLang = useSelector((state) => state.language.selectedLang);
  const { t } = useTranslation();
  const { min, max, valids, colors, handleChange } = props;

  useEffect(() => {
    setOptions({
      theme: "ios",
      themeVariant: "light",
      locale:
        selectedLang === "fr"
          ? localeFr
          : selectedLang === "en"
          ? localeEn
          : localeDe,
    });
  }, [selectedLang]);

  return (
    <div>
      <Datepicker
        // timezonePlugin={momentTimezone}
        // dataTimezone='utc'
        // displayTimezone='Europe/Berlin'
        display="inline"
        controls={["calendar"]}
        select="range"
        calendarType="month"
        showRangeLabels={true}
        inRangeInvalid={false}
        rangeEndInvalid={true}
        min={min}
        max={max}
        colors={colors}
        valid={valids}
        onChange={handleChange}
        dateFormat="YYYY-MM-DD"
        rangeStartLabel={t("check_in")}
        rangeEndLabel={t("check_out")}
        rangeStartHelp={t("choose_date")}
        rangeEndHelp={t("choose_date")}
        theme="my-theme"
        touchUi="auto"
        responsive={{
          xsmall: {
            display: "inline",
            pages: 1,
          },
          medium: {
            display: "inline",
            pages: 2,
          },
          large: {
            display: "inline",
            pages: 2,
          },
          custom: {
            breakpoint: 600,
            pages: 2,
          },
        }}
        className="w-full lg:min-w-[700px] xl:min-w-[900px] rounded shadow "
      />

      {colors?.length && (
        <div className="my-4 flex gap-2 items-center px-2">
          <div
            className="w-5 h-5 rounded-full"
            style={{ backgroundColor: colors[0].background }}
          ></div>
          <span className="text-xs md:text-sm mt-[3px]">
            {t("closing_date")}
          </span>
        </div>
      )}
   <p className="font-semibold p-2">
  {selectedLang === "fr" ? (
    <>
      Nous vous informons que notre établissement est ouvert <strong>mardi</strong>,{" "}
      <strong>mercredi</strong> (au dîner uniquement pour le restaurant), <strong>jeudi</strong>,{" "}
      <strong>vendredi</strong> et <strong>samedi</strong>. Nous sommes fermés <strong>dimanche</strong> et <strong>lundi</strong> ainsi que <strong>mercredi</strong> au déjeuner.
    </>
  ) : selectedLang === "en" ? (
    <>
      Please note that Villa René Lalique is open on <strong>Tuesdays</strong>, <strong>Wednesdays</strong> (for dinner only at the restaurant), <strong>Thursdays</strong>, <strong>Fridays</strong> and <strong>Saturdays</strong>. We are closed on <strong>Sundays</strong> and <strong>Mondays</strong> as well as <strong>Wednesdays</strong> for lunch.
    </>
  ) : (
    <>
      Bitte beachten Sie, dass Villa René Lalique <strong>dienstags</strong>, <strong>mittwochs</strong> (nur zum Abendessen für das Restaurant), <strong>donnerstags</strong>, <strong>freitags</strong> und <strong>samstags</strong> geöffnet ist. <strong>Sonntags</strong> und <strong>montags</strong> sowie <strong>mittwochs</strong> zum Mittagessen sind wir geschlossen.
    </>
  )}
</p>

    </div>
  );
}

export default HotelCalendar;
