import React, { useEffect, useState } from "react";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedLang } from "../../store/slices/languageSlice";
import { useLocation, useNavigate, useParams } from "react-router";
import i18n from "../../utils/i18n";

function LanguageDropdown() {
  const { lang: urlLang } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedLang, setSelectedLangState] = useState(urlLang);

  const langs = [
    { name: "Français", value: "fr" },
    { name: "English", value: "en" },
    { name: "Deutsch", value: "de" },
  ];

  const handleLanguageDropdown = (e) => {
    const newLang = e.target.value;
    dispatch(setSelectedLang(newLang));
    i18n.changeLanguage(newLang);
    const newPath = location.pathname.replace(
      /^\/[a-z]{2}(\/|$)/,
      `/${newLang}$1`
    );
    navigate(newPath, { replace: true });
  };

  useEffect(() => {
    if (urlLang && urlLang !== i18n.language) {
      i18n.changeLanguage(urlLang);
      dispatch(setSelectedLang(urlLang));
      setSelectedLangState(urlLang);
    }
  }, [urlLang, dispatch]);

  return (
    <div className="right-5 top-5 flex items-center gap-2 text-[#edeaea] absolute z-20">
      <FontAwesomeIcon icon={faLanguage} size="xl" />

      <select
        className="appearance-none text-sm rounded-md p-1 font-semibold text-white text-center bg-[#343435] shadow-md hover:bg-[#29292b] cursor-pointer focus:outline-none"
        onChange={handleLanguageDropdown}
        value={urlLang}
      >
        {langs.map((lang, i) => (
          <option key={i} value={lang.value}>
            {lang.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default LanguageDropdown;
