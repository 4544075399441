import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function RoomItem({ room, options }) {
  const [showDetails, setShowDetails] = useState(false);
  const { selectedLang } = useSelector((state) => state.language);
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex flex-col pl-2">
        <span className="font-semibold  w-fit rounded pt-1">
          {t("room")} {room.name}
        </span>

        <button
          onClick={() => {
            setShowDetails((prevState) => !prevState);
          }}
          className="text-left text-xs max-md:text-sm cursor-pointer flex gap-1 items-center text-sky-600  hover:text-sky-700 my-1"
        >
          {showDetails
            ? `${t("hide_details_room")}`
            : `${t("show_details_room")}`}
          {!showDetails ? (
            <FontAwesomeIcon
              icon={faAngleDown}
              className="text-xs max-md:text-sm"
            />
          ) : (
            <FontAwesomeIcon
              icon={faAngleUp}
              className="text-xs max-md:text-sm"
            />
          )}
        </button>
      </div>

      {showDetails && (
        <ul className="pl-2 text-sm">
          {room.children !== 0 && (
            <li className="flex ">
              <span>Nombre d'enfants</span>
              <span className="font-semibold">{room.children}</span>
            </li>
          )}

          <li className="flex gap-2">
            <span>{t("nb_person")}:</span>
            <span className="font-semibold">
              {room.adults} {t("person")}
            </span>
          </li>
          {room.options?.map((option, i) =>
            options.map((o) => {
              if (option.code === o.code) {
                return (
                  <li key={i} className="flex ">
                    <span>
                      {selectedLang === "fr"
                        ? o.name_fr
                        : selectedLang === "en"
                        ? o.name_en
                        : o.name_de}
                    </span>
                    <span className="font-semibold pl-1">{` x ${option.quantity}`}</span>
                  </li>
                );
              }
            })
          )}
        </ul>
      )}
    </div>
  );
}

export default RoomItem;
