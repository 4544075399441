import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeOption,
  resetBasketState,
  setTotalPrice,
} from "../../../store/slices/bookingParamSlice";
import {
  faAngleDown,
  faAngleUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import useLangPath from "../../../hooks/useLangPath";

function BasketItem({ item, index, rooms, totalNights, amount_tourism_tax }) {
  const createPath = useLangPath();
  const [showDetails, setShowDetails] = useState(true);
  const { room_type, name } = item;
  const selectedLang = useSelector((state) => state.language.selectedLang);
  const [totalRoom, setTotalRoom] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  //total price calcule
  useEffect(() => {
    let prices = [];
    rooms?.forEach((room) => {
      prices.push(room.room_total_price);
      prices.push(room.adults * totalNights * amount_tourism_tax);
      prices = prices.filter(function (element) {
        return element !== undefined;
      });
      room.options?.forEach((option) => {
        if (option.entire_stay) {
          prices.push(option.unit_price * option.quantity * totalNights);
        } else {
          prices.push(option.unit_price * option.quantity);
        }
      });

      let totalPrice = prices.reduce((partialSum, a) => partialSum + a, 0);

      totalPrice = (Math.round(totalPrice * 100) / 100).toFixed(2);
      return dispatch(setTotalPrice(totalPrice));
    });
  }, [rooms, dispatch, totalNights, amount_tourism_tax]);

  useEffect(() => {
    let prices = [];
    prices.push(rooms[index].room_total_price);
    rooms[index].options.forEach((option) => {
      if (option.entire_stay) {
        prices.push(option.unit_price * option.quantity * totalNights);
      } else {
        prices.push(option.unit_price * option.quantity);
      }
    });
    prices = prices.filter(function (element) {
      return element !== undefined;
    });
    let totalPrice = prices.reduce((partialSum, a) => partialSum + a, 0);

    if (prices.length > 0) {
      totalPrice += rooms[index].adults * totalNights * amount_tourism_tax;
    }

    totalPrice = (Math.round(totalPrice * 100) / 100).toFixed(2);
    return setTotalRoom(totalPrice);
  }, [rooms, index, totalNights, amount_tourism_tax]);

  useEffect(() => {
    rooms.length > 1 && setShowDetails(false);
  }, [rooms]);

  return (
    <>
      {room_type && (
        <div className="mb-3 px-4 text-sm max-md:text-base py-2">
          <div className="flex justify-between items-center font-semibold ">
            <span className="mb-1">
              {t("room")} {index + 1} ({name})
            </span>
            <span className="">
              {" "}
              {`${(Math.round(totalRoom * 100) / 100).toFixed(2)} €`}
            </span>
          </div>
          <div className="flex items-center justify-between ">
            <div className="flex gap-1 items-center text-sky-600  hover:text-sky-700 ">
              <button
                onClick={() => setShowDetails((prevState) => !prevState)}
                className="text-left text-xs max-md:text-sm cursor-pointer "
              >
                {showDetails
                  ? `${t("hide_details_room")}`
                  : `${t("show_details_room")}`}
              </button>
              {!showDetails ? (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="text-xs max-md:text-sm"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className="text-xs max-md:text-sm"
                />
              )}
            </div>
            {(rooms.length === 1 && location.pathname.includes("restaurant")) ||
            (rooms.length === 1 && location.pathname.includes("addons")) ||
            (rooms.length === 1 &&
              location.pathname.includes("personal-information")) ? (
              <button
                onClick={() => {
                  dispatch(resetBasketState());
                  dispatch(setTotalPrice(0));
                  navigate(createPath("/hotel/availabilities/rooms"));
                }}
                className="text-primary hover:underline"
              >
                {t("change")}
              </button>
            ) : null}
          </div>
          {showDetails && (
            <div className="flex flex-col">
              <span className="">
                {rooms[index].adults} {t("person")}
              </span>
              <div className="flex justify-between">
                <span>{t("night_price")}</span>
                <span className="">
                  {`${(
                    Math.round(rooms[index].night_price * 100) / 100
                  ).toFixed(2)} €`}
                </span>
              </div>

              {rooms[index].options?.length > 0 ? (
                <div className="border-t mt-3 pt-2">
                  <span className="font-semibold">{t("options")}</span>

                  <div>
                    {rooms[index].options?.map((option, i) => {
                      return (
                        <div className="flex justify-between" key={i}>
                          <div className="flex gap-2">
                            <span>
                              {`${
                                option.code !== "PDJ"
                                  ? `${
                                      selectedLang === "fr"
                                        ? `${option.name_fr?.slice(0, 20)}...`
                                        : selectedLang === "en"
                                        ? `${option.name_en?.slice(0, 20)}...`
                                        : `${option.name_de?.slice(0, 20)}...`
                                    }`
                                  : `${
                                      selectedLang === "fr"
                                        ? `${option.name_fr}`
                                        : selectedLang === "en"
                                        ? `${option.name_en}`
                                        : `${option.name_de}`
                                    }`
                              }`}{" "}
                              {`${
                                option.entire_stay
                                  ? ` (${t("entire_stay")})`
                                  : `x ${option.quantity}`
                              } `}
                            </span>
                            <button
                              className={` text-red-700 mr-2 hover:text-red-600 ${
                                location.pathname.includes("payment") ||
                                location.pathname.includes(
                                  "personal-information"
                                ) ||
                                option.code === "PDJ"
                                  ? "hidden"
                                  : ""
                              }`}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                onClick={() =>
                                  dispatch(
                                    removeOption({
                                      code: option.code,
                                      index: index,
                                    })
                                  )
                                }
                              />
                            </button>
                          </div>

                          <span className="">
                            {option.entire_stay
                              ? `${(
                                  Math.round(
                                    option.unit_price *
                                      option.quantity *
                                      totalNights *
                                      100
                                  ) / 100
                                ).toFixed(2)} €`
                              : `${(
                                  Math.round(
                                    option.unit_price * option.quantity * 100
                                  ) / 100
                                ).toFixed(2)} €`}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              <div className="flex justify-between border-t mt-2 pt-2">
                <span>{t("city_taxe")}</span>
                <span className="">
                  {`${(
                    Math.round(
                      amount_tourism_tax *
                        rooms[index].adults *
                        totalNights *
                        100
                    ) / 100
                  ).toFixed(2)} €`}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default BasketItem;
