import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Datepicker,
  localeDe,
  localeEn,
  localeFr,
  setOptions,
} from "@mobiscroll/react";
import { useEffect } from "react";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { addTable } from "../../store/slices/restaurantHotelSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { setTotalAdultsTable } from "../../store/slices/bookingParamSlice";
import MenusDropdown from "../../components/MenusDropdown/MenusDropdown";
import moment from "moment-timezone";
import { momentTimezone } from "@mobiscroll/react";
momentTimezone.moment = moment;

function HotelRestaurantBooking() {
  const { startDate, endDate, rooms, totalAdults } = useSelector(
    (state) => state.bookingParam
  );
  const availabilities = useSelector(
    (state) => state.availabilities.availabilities
  );
  const [selectedAdult, setSelectedAdult] = useState();
  const [selectedDate, setSelectedDate] = useState(startDate);
  const [calendarDate, setCalendarDate] = useState();
  const [selectedMenu, setSelectedMenu] = useState({ name: "" });
  const [selectedHour, setSelectedHour] = useState("");
  const [comments, setComments] = useState("");
  const [activeHourBtn, setActiveHourBtn] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [activeBtn, setActiveBtn] = useState(null);
  const [menus, setMenus] = useState([]);
  const [hour, setHour] = useState([]);
  const selectedLang = useSelector((state) => state.language.selectedLang);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setOptions({
      theme: "ios",
      themeVariant: "light",
      locale:
        selectedLang === "fr"
          ? localeFr
          : selectedLang === "en"
          ? localeEn
          : localeDe,
    });
  }, [selectedLang]);

  useEffect(() => {
    setSelectedAdult(rooms[0].adults);
    setActiveBtn(rooms[0].adults - 1);
  }, [rooms]);

  useEffect(() => {
    const getMenus = async () => {
      setSelectedAdult(selectedAdult ? selectedAdult : rooms[0].adults);
      dispatch(setTotalAdultsTable(selectedAdult));
      setSelectedDate(selectedDate ? selectedDate : startDate);
      await axios
        .get(
          `/restaurant/availabilities/${selectedDate}/${
            selectedAdult ? selectedAdult : rooms[0].adults
          }/H`
        )
        .then((data) => {
          let dinner = data.data.services.find(
            (service) => service.name === "Dîner"
          );
          setHour(dinner);
          let arr = [];
          data.data.menus?.map((menu) => {
            menu?.available_on.forEach((m) => {
              if (
                m.weekday ===
                  new Date(selectedDate ? selectedDate : startDate).getDay() &&
                m.service === "S"
              ) {
                arr.push(menu);
              }
              setMenus(arr);
            });
          });

          if (
            data.data.services[0].hours.length === 0 &&
            data.data.services[1].hours.length === 0
          ) {
            setErrorMsg(`${t("table_not_found")}`);
          } else {
            setErrorMsg("");
          }
        })
        .catch((error) => console.log(error));
    };

    getMenus();
  }, [selectedDate, selectedAdult, startDate, rooms, activeBtn, selectedLang]);

  // days
  const getDaysArray = function (s, e) {
    const parisTimeZone = "Europe/Paris";
    let start = new Date(
      new Date(s).toLocaleString("en-US", { timeZone: parisTimeZone })
    );
    let end = new Date(
      new Date(e).toLocaleString("en-US", { timeZone: parisTimeZone })
    );

    let dates = [];

    while (start <= end) {
      dates.push(
        new Date(start.toLocaleString("en-US", { timeZone: parisTimeZone }))
      );

      start.setDate(start.getDate() + 1);
    }

    return dates;
  };

  let dates = [];
  let daylist = getDaysArray(new Date(startDate), new Date(endDate) );
  daylist.map((v) => dates.push(v));
  const handleAddTable = () => {
    dispatch(
      addTable({
        date: new Date(selectedDate).toISOString().slice(0, 10),
        service: "S",
        hours: selectedHour,
        adults: selectedAdult,
        children: 0,
        comments: comments,
        menu: selectedMenu.name,
        code: selectedMenu.code,
      })
    );

    let date = new Date(selectedDate);
    setCalendarDate(new Date(date.getTime() + 86400000));
    setSelectedDate(
      new Date(date.getTime() + 86400000).toISOString().slice(0, 10)
    );
    setSelectedHour("");
    setActiveHourBtn("");
    setComments("");
  };

  const handleDate = (event) => {
    setSelectedDate(event.valueText);
    setCalendarDate(event.valueText);
    // Vider les champs d'heure et autres valeurs associées
    setSelectedHour("");
    setActiveHourBtn(null);
    setComments("");
    setSelectedMenu({ name: "" });
  };

  const handleCommentBtn = (e) => {
    setComments(e.target.value);
  };

  if (!availabilities.length) return navigate("/hotel");

  return (
    <div className="flex flex-col w-full min-h-full border rounded-md bg-white">
      <div className="text-sm italic p-5 border-b">
        <p id="resto_p" className="italic mb-2 font-semibold">
          {t("hotel_rest_group_msg")}
        </p>
        <p className="italic ">{t("hotel_rest_contact_msg")}</p>
      </div>
      <div className="flex gap-2 justify-center sm:justify-start px-7 py-4 border-b flex-col rounded-md">
        <p className="text-lg font-semibold">
          {t("chose_nb_guest")} <span className="text-red-500">*</span>
        </p>
        <div className="flex flex-row flex-wrap">
          {Array(totalAdults)
            .fill(null)
            .map((el, i) => (
              <button
                className={`border w-12 h-8 rounded-md mr-2 hover:bg-primary duration-300 font-semibold ${
                  activeBtn === i && "bg-primary text-white font-semibold"
                }`}
                key={i}
                onClick={() => {
                  setSelectedAdult(i + 1);
                  setActiveBtn(i);
                }}
              >
                {i + 1}
              </button>
            ))}
        </div>
        <span className="text-red-600 text-sm">{errorMsg}</span>
      </div>
      <div className="flex gap-2 justify-center sm:justify-start px-7 py-4 border-b bg-white  flex-col">
        <p className="max-w:fit text-lg font-semibold">
          {t("chose_date")} <span className="text-red-500">*</span>
        </p>
        <div className="flex flex-col lg:flex-row ">
          <Datepicker
            timezonePlugin={momentTimezone}
            dataTimezone="utc"
            displayTimezone="Europe/Paris"
            display="inline"
            controls={["calendar"]}
            selectMultiple={false}
            calendarType="month"
            theme="my-theme"
            valid={dates}
            onChange={handleDate}
            value={calendarDate ? calendarDate : startDate}
            dateFormat="YYYY-MM-DD"
            className="w-full"
          />

          {selectedAdult > 0 &&
            selectedDate.length > 0 &&
            hour.hours?.length > 0 && (
              <div className="max-lg:mt-4 lg:px-5 flex flex-col">
                <div>
                  <p className="pb-2 text-lg font-semibold">
                    {t("chose_time")} <span className="text-red-500">*</span>
                  </p>
                  <div className="flex gap-2 flex-wrap">
                    {hour.hours?.map((hour, i) => (
                      <button
                        className={`border w-16 h-8 rounded-md mr-2 hover:bg-primary duration-300 font-semibold ${
                          activeHourBtn === i &&
                          "bg-primary text-white font-semibold"
                        }`}
                        key={i}
                        onClick={() => {
                          setSelectedHour(hour);
                          setActiveHourBtn(i);
                        }}
                      >
                        {hour}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>

      {selectedAdult > 0 && selectedDate.length > 0 && hour.hours?.length > 0 && (
        <div className="bg-white  py-5 px-5 flex gap-5 flex-col md:flex-row md:items-end">
          <div>
            <p className="pb-2 text-lg font-semibold"> {t("chose_menu")}</p>
            <MenusDropdown
              options={menus}
              value={selectedMenu}
              onChange={(o) => setSelectedMenu(o)}
              selectedDate={selectedDate}
            />
          </div>

          <div>
            {menus?.map((menu, i) => {
              if (menu.name === selectedMenu.name) {
                return (
                  <a
                    href={`${
                      selectedLang === "fr"
                        ? menu.description_fr
                        : selectedLang === "en"
                        ? menu.description_en
                        : menu.description_de
                    }`}
                    target="_blank"
                    rel="noreferrer"
                    key={i}
                    className="underline hover:text-primary duration-300"
                  >
                    {" "}
                    {`${t("see_menu")} "${menu.name}"`}
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      className="ml-2"
                    />
                  </a>
                );
              }
            })}
          </div>
        </div>
      )}

      <div className=" rounded p-1 w-full flex flex-col px-5 bg-white mb-3 border-t pt-4">
        <label className="pb-2 text-lg font-semibold"> {t("comments")}</label>
        <textarea
          placeholder={`${t("comments_placeholder_resto")}`}
          type="text"
          onChange={handleCommentBtn}
          className="border rounded p-2 text-sm"
          value={comments}
        />
      </div>

      <button
        className={`bg-secondary hover:bg-primary rounded text-white text-lg py-2 px-4 mt-3 my-5 self-center w-1/2 md:py-2 ${
          selectedHour.length < 1 ? "pointer-events-none !bg-gray-400" : ""
        }`}
        onClick={handleAddTable}
      >
        {t("validate")}
      </button>
    </div>
  );
}

export default HotelRestaurantBooking;
