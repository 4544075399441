import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BookingDates from "./BookingDates";
import HotelCalendar from "./HotelCalendar/HotelCalendar";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import {
  resetState,
  setEndDate,
  setStartDate,
  setTotalNights,
} from "../../store/slices/bookingParamSlice";
import useFetchData from "../../hooks/useFetchData";
import Spinner from "../../components/Spinner/Spinner";
import {
  setAppSettings,
  setHotelSettings,
} from "../../store/slices/settingsSlice";
import axios from "../../api/axios";
import { useTranslation } from "react-i18next";
import { setOptions } from "../../store/slices/optionsSlice";
import { setAvailabilities } from "../../store/slices/availabilitiesSlice";
import { useLocation } from "react-router";
import { resetRestaurantState } from "../../store/slices/restaurantHotelSlice";

function HotelDates() {
  const { rooms, startDate, endDate } = useSelector(
    (state) => state.bookingParam
  );
  const [notAvailable, setNotAvailable] = useState(false);
  const [availDates, setAvailDates] = useState([]);
  const [closureDates, setClosureDates] = useState();
  const [guestsData, setGuestsData] = useState([{ guests: 2 }]);
  const [extraPrice, setExtraPrice] = useState(false);
  const { appSettings } = useSelector((state) => state.settings);
  let year = new Date().getFullYear();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const location = useLocation();

  const handleResetState = () => {
    sessionStorage.clear();
    dispatch(resetState());
    dispatch(resetRestaurantState());
  };

  useEffect(() => {
    window.addEventListener("popstate", handleResetState());
  }, [dispatch, location]);

  useEffect(() => {
    let arrRoom = [];
    rooms.map((room) => {
      arrRoom.push({ guests: room.adults });
      room.adults > 2 && setExtraPrice(true);
    });
    setGuestsData(arrRoom);
  }, [rooms]);

  const { data: closingData } = useFetchData(`/company/closures/`);
  const { data: options } = useFetchData(`/hotel/active`);

  useEffect(() => {
    let arr = [];
    closingData.forEach((dates) => {
      if (dates.closure_type === "Hôtel") {
        arr.push(dates);
      }
      setClosureDates(arr);
    });
  }, [closingData]);

  const { data: calendarData, loading: calendarLoading } = useFetchData(
    `/hotel/availability/dates/${year}/${JSON.stringify(guestsData)}/`
  );


  useEffect(() => {
    if (startDate.length && endDate.length && guestsData) {
      const getData = async () => {
        try {
          const response = await axios.get(
            `/hotel/availability/rooms-by-period/${startDate}/${endDate}/${JSON.stringify(guestsData)}/`
          );
  
          const data = response.data;
  
          if (Array.isArray(data)) {
            setNotAvailable(data.length === 0);
  
            let arr = [];
            data.forEach((room) => {
              if (extraPrice && room.max_capacity > 2) {
                arr.push({
                  ...room,
                  night_price: room.night_price + room.additional_pax_price,
                  total_price: room.total_price + room.additional_pax_price * room.no_nights,
                });
              }
  
              room.prices.forEach((item) => {
                arr.push({
                  ...room,
                  prices: [
                    ...room.prices,
                    {
                      ...item,
                      price: Number(item.price) + room.additional_pax_price,
                    },
                  ],
                });
              });
  
              dispatch(setTotalNights(room.no_nights));
            });
  
            const uniq = [...new Set(arr)];
            if (uniq.length) {
              setAvailDates(uniq);
              dispatch(setAvailabilities(uniq));
            } else {
              setAvailDates(data);
              dispatch(setAvailabilities(data));
            }
          }
        } catch (error) {
          console.error(error.response?.status || error.message);
        }
      };
  
      dispatch(setOptions(options));
      getData();
    }
  }, [startDate, endDate, guestsData]);
  


  useEffect(() => {
    if (!startDate.length || !endDate.length) {
      setNotAvailable(false);
    }
  }, [startDate, endDate]);

  const { data: settingsData } = useFetchData(`/hotel/settings`);

  useEffect(() => {
    dispatch(setHotelSettings(settingsData));
    if (Object.keys(appSettings).length === 0) {
      axios
        .get("/company/settings")
        .then((data) => dispatch(setAppSettings(data.data)))
        .catch((error) => console.log(error));
    }
  }, [settingsData, appSettings, dispatch]);

  const min = settingsData.planning_start;
  const max = settingsData.planning_end;

  const handleChange = (e) => {
    dispatch(setStartDate(e.inst._tempStartText));
    if (e.inst._tempStartText !== e.inst._tempEndText) {
      dispatch(setEndDate(e.inst._tempEndText));
    }
  };

  return (
    <div className="bg-slate-50 xl:px-12 md:min-h-[68.8vh] pb-10 xl:pb-24 2xl:px-32">
      <h1 className="mx-7 mb-3 pt-6  border-b w-fit text-xl border-gray-300 pr-2 max-md:hidden font-semibold">
        {t("book_room")}
      </h1>
      <div className="mx-7 max-w-3xl italic max-md:hidden">
        <p className="font-semibold text-sm">{t("group_bkg_title")}</p>{" "}
        <p id="dates_p" className="leading-none pb-2 text-sm">
          {t("group_bkg_text")}
        </p>
        <p className="leading-none pb-2 text-sm font-semibold">
          {t("not_payment_cheque")}
        </p>
      </div>

      <div className="flex flex-col lg:flex-row md:justify-center md:gap-6 md:px-7">
        {calendarLoading ? (
          <Spinner />
        ) : (
          <HotelCalendar
            min={min}
            max={max}
            valids={calendarData}
            colors={closureDates}
            handleChange={handleChange}
            startDate={startDate}
            endDate={endDate}
          />
        )}
        <BookingDates
          startDate={startDate}
          endDate={endDate}
          adults={rooms.adults}
          notAvailable={notAvailable}
        />
      </div>
    </div>
  );
}

export default HotelDates;
