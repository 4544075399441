import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "../../api/axios";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import Payment from "../Payment/Payment";
import { setHotelSettings } from "../../store/slices/settingsSlice";
import useFetchData from "../../hooks/useFetchData";
import { setRestaurantBooking } from "../../store/slices/restaurantHotelSlice";
import { useTranslation } from "react-i18next";
import RoomItem from "./RoomItem/RoomItem";
import NotFound from "../NotFound/NotFound";
import {
  setEmail,
  setErrorMsg,
  setMode,
  setOrderId,
  setReservationType,
  setTotalAdults,
  setTotalAmountToBlock,
  setTotalNights,
} from "../../store/slices/bookingParamSlice";
import { setSelectedLang } from "../../store/slices/languageSlice";

function IndependantPayment() {
  let { uuid } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [customerData, setCustomerData] = useState();
  const [hasError, setHasError] = useState(false);
  const selectedLang = useSelector((state) => state.language.selectedLang);
  const [amount, setAmount] = useState();

  const { data: settingsData } = useFetchData(`/hotel/settings`);
  const { data: optionsData } = useFetchData(`/hotel/all`);

  useEffect(() => {
    if (uuid) {
      dispatch(setHotelSettings(settingsData));
      getData();
    }
  }, [uuid, settingsData, dispatch]);

  const getData = async () => {
    try {
      const { data } = await axios.get(`base/reservation/get_by_uuid/${uuid}`);
      setAmount(data.imprint_amount);
      dispatch(setTotalAmountToBlock(data.imprint_amount));
      dispatch(setSelectedLang(data.lang.toLowerCase()));
      
      if (data.error) {
        dispatch(setErrorMsg(data.error));
        setHasError(true);
      } else {
        if (data.reference.charAt(0) === "H") {
          let arr = [];
          if (data.reservations_restaurant?.length) {
            data.reservations_restaurant.forEach((resto) => {
              arr.push(resto.adults);
              dispatch(setTotalAdults(arr.reduce((partialSum, a) => partialSum + a, 0)));
            });
          }
        } else {
          dispatch(setTotalAdults(data.adults));
        }

        const oneDay = 24 * 60 * 60 * 1000;
        const firstDate = new Date(data.check_in);
        const secondDate = new Date(data.check_out);
        const totalNights = Math.round(Math.abs((firstDate - secondDate) / oneDay));

        setCustomerData(data);
        dispatch(setRestaurantBooking(data.reservations_restaurant));
        dispatch(setOrderId(data.reference));
        dispatch(setEmail(data.email));
        dispatch(setMode(1));
        dispatch(setTotalNights(totalNights));
        dispatch(setReservationType(`${data.reference.charAt(0) === "R" ? "R" : "H"}`));

        setHasError(false);
        dispatch(setErrorMsg(""));
      }
    } catch (error) {
      setHasError(true);
      dispatch(setErrorMsg("Something went wrong..."));
    }
  };

  // date formatting
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  function formatUTCDateString(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString(selectedLang, {
      ...options,
      timeZone: 'UTC' // Ensuring the date is treated as UTC
    });
  }

  if (hasError) return <NotFound />;

  return (
    <>
      {customerData && (
        <div className="">
          <Header />
          <div className="flex flex-col md:flex-row gap-5 bg-slate-100 px-5 py-5 min-h-[85vh]">
            <div className="bg-white h-fit md:w-1/2 rounded p-5 shadow-md">
              <h2 className="pb-5 font-semibold text-xl text-center md:text-left">
                {t("reacap_h_r")}
              </h2>

              <div className="flex flex-col gap-7">
                <div>
                  <p className="border-b w-full mb-3 text-lg">
                    {t("form_title")}
                  </p>
                  <ul className="flex gap-5 flex-wrap pl-2">
                    <li className="flex flex-col">
                      <span className="text-gray-400 text-sm">
                        {t("last_name")}
                      </span>
                      <span className="font-semibold w-fit rounded pt-1">
                        {customerData.last_name}
                      </span>
                    </li>
                    <li className="flex flex-col">
                      <span className="text-gray-400 text-sm">
                        {t("first_name")}
                      </span>
                      <span className="font-semibold w-fit rounded pt-1">
                        {customerData.first_name}
                      </span>
                    </li>
                    <li className="flex flex-col">
                      <span className="text-gray-400 text-sm">
                        {t("email")}
                      </span>
                      <span className="font-semibold w-fit rounded pt-1">
                        {customerData.email}
                      </span>
                    </li>
                    <li className="flex flex-col">
                      <span className="text-gray-400 text-sm">
                        {t("phone")}
                      </span>
                      <span className="font-semibold w-fit rounded pt-1">
                        {customerData.phone}
                      </span>
                    </li>
                    <li className="flex flex-col">
                      <span className="text-gray-400 text-sm">
                        {t("reference")}
                      </span>
                      <span className="font-semibold w-fit rounded pt-1">
                        {customerData.reference}
                      </span>
                    </li>
                  </ul>
                </div>

                {customerData.reference?.charAt(0) === "H" && (
                  <div>
                    <h3 className="border-b w-full mb-3 text-lg">
                      {t("room_bkg_details")}
                    </h3>
                    <ul className="flex gap-5 mb-2 pl-2">
                      <li className="flex flex-col">
                        <span className="text-gray-400 text-sm">
                          {t("check_in")}
                        </span>
                        <span className="font-semibold w-fit rounded pt-1">
                          {formatUTCDateString(customerData.check_in)}
                        </span>
                      </li>
                      <li className="flex flex-col">
                        <span className="text-gray-400 text-sm">
                          {t("check_out")}
                        </span>
                        <span className="font-semibold w-fit rounded pt-1">
                          {formatUTCDateString(customerData.check_out)}
                        </span>
                      </li>
                    </ul>
                    <div className="flex gap-5 flex-wrap">
                      {customerData.rooms?.map((room, i) => (
                        <RoomItem room={room} key={i} options={optionsData} />
                      ))}
                    </div>
                    {customerData.reservations_restaurant.length ? (
                      <div className="mt-5">
                        <h3 className="border-b w-full mb-3 text-lg">
                          {t("table_bkg_details")}
                        </h3>
                        {customerData.reservations_restaurant?.map(
                          (table, i) => {
                            return (
                              <div className="pl-2" key={i}>
                                <p className="font-semibold">
                                  {t("table_bkg_text", {
                                    date: formatUTCDateString(table.date),
                                    adults: table.adults,
                                    hour: table.hours.slice(0, 5),
                                  })}
                                </p>
                                <p className="pt-1">
                                  {table.menu
                                    ? `${t("menu_text", { menu: table.menu })} `
                                    : `${t("menu_choice")}`}
                                </p>
                              </div>
                            );
                          }
                        )}
                      </div>
                    ) : null}
                  </div>
                )}
                {customerData?.reference.charAt(0) === "R" && (
                  <div>
                    <h3 className="border-b w-full mb-3">
                      {t("resto_title")}
                    </h3>
                    <p className="font-semibold">
                      {t("table_bkg_text", {
                        date: formatUTCDateString(customerData.date),
                        adults: customerData.adults,
                        hour: customerData.hours.slice(0, 5),
                      })}
                    </p>
                    <p>
                      {customerData.menu
                        ? `${t("menu_text", { menu: customerData.menu })} `
                        : `${t("menu_choice")}`}
                    </p>
                  </div>
                )}
                <ul className="border-t mt-2 pt-1">
                  <li className="flex justify-between">
                    <span className="text-gray-500 text-base font-semibold">
                      {t("total_blocked_amount")}
                    </span>
                    <span className="font-semibold text-gray-500 text-base">
                      {amount && `${amount.toFixed(2)} €`}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {customerData.reference && (
              <div className="bg-white h-fit md:w-1/2 rounded p-5 shadow-md">
                <h3 className="pb-5 font-semibold text-xl text-center md:text-left">
                  {t("payment")}
                </h3>
                <Payment />
              </div>
            )}
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default IndependantPayment;
