import { Datepicker } from "@mobiscroll/react";
import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import axios from "../../../api/axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

function WaitingList({
  restaurantData,
  settingsData,
  selectedDate,
  selectedAdult,
}) {
  const [showModal, setShowModal] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [activeService, setActiveService] = useState();
  const selectedLang = useSelector((state) => state.language.selectedLang);
  const [clicked, setClicked] = useState(false);
  const [service, setService] = useState([]);
  const [form, setForm] = useState({
    gender: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    number_of_people: 0,
    date: "",
    comments: "",
    lang: selectedLang,
    service: "",
  });
  const [activeBtn, setActiveBtn] = useState("");
  const { t } = useTranslation();

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });
  };

  useEffect(() => {
    if (form.number_of_people && form.date) {
      axios
        .get(
          `/restaurant/availabilities/${form.date}/${form.number_of_people}/R`
        )
        .then((data) => {
          let arr = [];
          data.data.services.map((s) => {
            if (!s.hours.length) {
              arr.push(s.name);
            }
          });
          setService(arr);
        })
        .catch((error) => console.log(error));
    }
  }, [form]);

  useEffect(() => {
    setForm({ ...form, date: selectedDate, number_of_people: selectedAdult });
    setActiveBtn(selectedAdult - 1);
  }, [selectedDate, selectedAdult]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      form.first_name.length >= 2 &&
      form.gender.length &&
      form.last_name.length >= 2 &&
      form.email.length >= 2 &&
      form.phone.length >= 7 &&
      form.date.length &&
      form.service.length &&
      form.number_of_people > 0 &&
      clicked
    ) {
      axios
        .post("/restaurant/waiting_list/create", form)
        .then((data) => {
          setSuccessMsg(`${t("wl_confirm")}`);
          setDisabledBtn(true);
          setErrorMsg("");
        })
        .catch((error) => {
          if (error) {
            setErrorMsg(`${t("wl_error")}`);
            setSuccessMsg("");
          }
        });
    } else {
      // setErrorMsg(`${t("wl_error")}`);
      // setSuccessMsg("");
    }
  };

  const addDate = (e) => {
    setForm({ ...form, date: e.valueText });
  };

  const closeForm = () => {
    setShowModal(false);
    setForm({
      gender: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      number_of_people: 0,
      date: "",
      lang: selectedLang,
      comments: "",
      service: "",
    });
    setErrorMsg("");
    setSuccessMsg("");
    setDisabledBtn(false);
    setActiveBtn("");
    setActiveService("");
    setClicked(false);
  };

  const services = [
    {
      name: `${t("lunch")}`,
      code: "M",
    },
    {
      name: `${t("dinner")}`,
      code: "S",
    },
  ];

  const d = new Date(form.date);
  let day = d.getDay();

  service.forEach((s) => {
    if (s === "Dîner") {
      services[1].active = true;
    } else if (s === "Déjeuner" && day !== 6) {
      services[0].active = true;
    }
  });

  const activeServ = services.filter((element) => element.active);

  return (
    <>
      <button
        className="my-2 border border-primary bg-white text-primary rounded-md px-2 py-1 w-fit hover:bg-primary hover:text-white  duration-300"
        type="button"
        onClick={() => setShowModal(true)}
      >
        {t("sign_up")}
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
            <div className="relative w-auto my-6 mx-auto max-w-3xl ">
              <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ">
                {!successMsg.length ? (
                  <form
                    onSubmit={handleSubmit}
                    className="flex flex-col gap-3 bg-white p-5 z-10 rounded-lg md:px-10 "
                  >
                    <button
                      onClick={closeForm}
                      className="text-center border w-fit p-0.5 px-2 rounded self-end bg-black text-white font-semibold hover:bg-opacity-70 cursor-pointer"
                    >
                      X
                    </button>
                    <h2 className="mb-2 text-lg font-semibold text-center">
                      {t("sign_up_title")}
                    </h2>
                    <div className="flex flex-col ">
                      <label className="max-md:text-sm font-semibold ">
                        {t("civility")} <span className="text-red-500">*</span>
                      </label>
                      <select
                        name="civility"
                        type="text"
                        className="px-1 pr-2 border rounded placeholder:text-sm py-1 cursor-pointer"
                        onChange={(e) => {
                          setForm({ ...form, gender: e.target.value });
                        }}
                        defaultValue={""}
                      >
                        <option value="" disabled>
                          {t("choose_civility")}
                        </option>
                        <option value="mr"> {t("mr")}</option>
                        <option value="mme"> {t("mrs")}</option>
                      </select>
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="last_name"
                        className="max-md:text-sm font-semibold"
                      >
                        {t("last_name")}
                        <span className="text-red-500 pl-1">*</span>
                      </label>
                      <input
                        id="last_name"
                        type="text"
                        value={form.last_name}
                        onChange={handleChange}
                        className="border rounded py-1.5 border-gray-300 focus:outline-none pl-2"
                        placeholder={t("last_name")}
                        required
                      />
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="first_name"
                        className="max-md:text-sm font-semibold"
                      >
                        {t("first_name")}
                        <span className="text-red-500 pl-1">*</span>
                      </label>
                      <input
                        id="first_name"
                        type="text"
                        value={form.first_name}
                        onChange={handleChange}
                        placeholder={t("first_name")}
                        className="border rounded py-1.5 border-gray-300 focus:outline-none pl-2"
                        required
                      />
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="email"
                        className="max-md:text-sm font-semibold"
                      >
                        {t("email")}
                        <span className="text-red-500 pl-1">*</span>
                      </label>
                      <input
                        id="email"
                        type="text"
                        value={form.email}
                        onChange={handleChange}
                        placeholder={t("email")}
                        className="border rounded py-1.5 border-gray-300 focus:outline-none pl-2"
                        required
                      />
                    </div>

                    <div className="flex flex-col">
                      <label className="max-md:text-sm font-semibold">
                        {t("phone")}
                        <span className="text-red-500 pl-1">*</span>
                      </label>

                      <PhoneInput
                        country={"fr"}
                        specialLabel={""}
                        onChange={(val) => {
                          setForm({ ...form, phone: val });
                        }}
                        preferredCountries={["fr", "es", "de", "gb"]}
                        required
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="number_of_people"
                        className="max-md:text-sm font-semibold mb-2"
                      >
                        {t("nb_person")}{" "}
                        <span className="text-red-500 pl-1">*</span>
                      </label>
                      <div className="flex flex-row flex-wrap">
                        {Array(7)
                          .fill(null)
                          .map((el, i) => (
                            <button
                              className={`border w-12 h-8 rounded-md mr-2 hover:bg-primary duration-300 font-semibold ${
                                activeBtn === i &&
                                "bg-primary text-white font-semibold"
                              }`}
                              key={i}
                              onClick={() => {
                                setForm({ ...form, number_of_people: i + 1 });
                                setActiveBtn(i);
                              }}
                            >
                              {i + 1}
                            </button>
                          ))}
                      </div>
                    </div>
                    <div>
                      <span className="max-md:text-sm font-semibold">
                        {t("date")}
                        <span className="text-red-500 pl-1">*</span>
                      </span>
                      <div className="border rounded py-1.5 border-gray-300 focus:outline-none pl-2">
                        <Datepicker
                          controls={["calendar"]}
                          inputComponent="input"
                          theme="my-theme"
                          valid={restaurantData}
                          inputProps={{
                            placeholder: `${t("choose_date_placeholder")}`,
                          }}
                          onChange={addDate}
                          dateFormat="YYYY-MM-DD"
                          defaultValue={selectedDate}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="service"
                        className="max-md:text-sm font-semibold mb-2"
                      >
                        Service
                        <span className="text-red-500 pl-1">*</span>
                      </label>

                      {form.date.length && activeServ.length ? (
                        <div className="flex flex-row flex-wrap">
                          {services.map(
                            (s) =>
                              s.active && (
                                <button
                                  className={`border w-fit px-1 h-8 rounded-md mr-2 hover:bg-primary duration-300 font-semibold ${
                                    activeService === s.code &&
                                    "bg-primary text-white font-semibold"
                                  }`}
                                  key={s.code}
                                  onClick={() => {
                                    setForm({ ...form, service: s.code });
                                    setActiveService(s.code);
                                  }}
                                >
                                  {s.name}
                                </button>
                              )
                          )}
                        </div>
                      ) : (
                        <p className="">{t("wl_spot_msg")}</p>
                      )}
                    </div>

                    <div className="flex flex-col">
                      <label htmlFor="comments" className="max-md:text-sm">
                        {t("comments")} (optional)
                      </label>
                      <textarea
                        id="comments"
                        type="text"
                        value={form.comments}
                        onChange={handleChange}
                        className="border rounded py-2 border-gray-300 pl-2 placeholder:text-sm placeholder:leading-none"
                        placeholder={t("comments_placeholder_resto")}
                      />
                    </div>
                    {errorMsg && (
                      <p className="text-red-600 text-center">{errorMsg}</p>
                    )}
                    <button
                      onClick={() => setClicked(true)}
                      className={`${
                        disabledBtn
                          ? "bg-gray-300 cursor-default pointer-events-none"
                          : "duration-300 bg-primary  cursor-pointer hover:bg-opacity-75  hover:border-primary"
                      }  text-white border-transparent px-2 py-2 rounded  mt-4`}
                    >
                      {t("send")}
                    </button>
                  </form>
                ) : (
                  <div className="flex flex-col p-5 gap-2">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-3xl text-green-700"
                    />
                    <p className="font-semibold ">{successMsg}</p>
                  </div>
                )}
                <button
                  onClick={closeForm}
                  className="duration-300 rounded p-1  hover:text-red-600 mb-4"
                >
                  {t("close")}
                </button>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default WaitingList;
