import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faCalendarCheck,
  faCalendarXmark,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useLangPath from "../../../hooks/useLangPath";

function BookingDates(props) {
  const createPath = useLangPath();
  const { t } = useTranslation();
  const { startDate, endDate, notAvailable } = props;
  const selectedLang = useSelector((state) => state.language.selectedLang);

  var options = {
    timeZone: "Europe/Paris",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  function toLocaleUTCDateString(date, locales, options) {
    const timeDiff = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.valueOf() + timeDiff);
    return adjustedDate.toLocaleDateString(locales, options);
  }

  let formatedStartD = toLocaleUTCDateString(
    new Date(startDate),
    `${
      selectedLang === "fr"
        ? "fr-FR"
        : selectedLang === "en"
        ? "en-US"
        : "de-DE"
    }`,
    options
  );

  let formatedEndD = toLocaleUTCDateString(
    new Date(endDate),
    `${
      selectedLang === "fr"
        ? "fr-FR"
        : selectedLang === "en"
        ? "en-US"
        : "de-DE"
    }`,
    options
  );

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col gap-6 bg-white border rounded-tl rounded-tr p-7 md:p-5 2xl:flex-row 2xl:justify-center max-md:hidden">
        <div className="flex md:flex-col gap-2 md:gap-0 items-center">
          <div className="flex md:flex-col gap-1">
            <FontAwesomeIcon icon={faCalendarCheck} className="md:text-2xl" />
            <span>
              {t("check_in")}
              {selectedLang === "fr" ? " :" : ":"}
            </span>
          </div>
          <span className="font-semibold">
            {startDate !== "Invalid Date" && startDate.length > 0 ? (
              <div className="items-center flex gap-1">
                <span className="text-2xl text-center">{formatedStartD}</span>
              </div>
            ) : (
              <span></span>
            )}
          </span>
        </div>
        <div className="flex md:flex-col gap-2 md:gap-0 items-center ">
          <div className="flex md:flex-col gap-1 ">
            <FontAwesomeIcon icon={faCalendarXmark} className="md:text-2xl" />
            <span>
              {t("check_out")}
              {selectedLang === "fr" ? " :" : ":"}
            </span>
          </div>
          <span className="font-semibold ">
            {endDate !== "Invalid Date" && endDate.length > 0 ? (
              <div className="items-center flex gap-1">
                <span className="text-2xl text-center">{formatedEndD}</span>
              </div>
            ) : (
              <span></span>
            )}
          </span>
        </div>
      </div>

      <Link
        to={createPath("/hotel/availabilities/rooms")}
        className={`duration-300 group bg-black text-white px-3 py-2 text-center rounded-bl rounded-br hover:bg-primary flex items-center justify-center ${
          !startDate.length || !endDate.length || notAvailable
            ? "bg-gray-500 cursor-default hover:bg-gray-500 pointer-events-none"
            : ""
        }`}
      >
        <span>{t("see_offers")}</span>
        <FontAwesomeIcon
          icon={faAngleRight}
          className="ml-1 text-sm group-hover:duration-1000 group-hover:translate-x-2 duration-1000"
        />
      </Link>
      {notAvailable ? (
        <div className="border p-5 mt-2 bg-white rounded-md  text-center flex flex-col gap-3">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-2xl text-red-700"
          />
          <span className="text-red-600">{t("dates_error")}</span>
        </div>
      ) : null}
    </div>
  );
}

export default BookingDates;
