import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import countries from "../../data/countries.json";
import { useLocation } from "react-router";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { setPhone, setCountry } from "../../store/slices/bookingParamSlice";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PhoneCustomInput from "./PhoneCustomInput";

const validate = (values) => {
  const errors = {};
  if (!values.lastName) {
    errors.lastName = "Required";
  } else if (values.lastName.length > 15) {
    errors.lastName = "Must be 15 characters or less";
  }
  if (!values.firstName) {
    errors.firstName = "Required";
  } else if (values.firstName.length > 15) {
    errors.firstName = "Must be 15 characters or less";
  }
  if (!values.phone) {
    errors.phone = "Required";
  } else if (values.phone.length <= 10) {
    errors.phone = "Must be at least 10 digits";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.street) {
    errors.street = "Required";
  }
  if (!values.civility) {
    errors.civility = "Required";
  }
  if (!values.city) {
    errors.city = "Required";
  }
  if (!values.zipCode) {
    errors.zipCode = "Required";
  }

  return errors;
};

const renderField = ({
  input,
  placeholder,
  type,
  defaultValue,
  meta: { touched, error },
  ...other
}) => (
  <div>
    <div>
      <input {...input} placeholder={placeholder} type={type} {...other} />
      {touched && error && <span className="text-red-500">{error}</span>}
    </div>
  </div>
);

const SyncValidationForm = (props) => {
  const defaultOption = { name: "France", code: "FR" };
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState();
  const [countryCode, setCountryCode] = useState(defaultOption.code);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setCountry(countryCode));
  }, [countryCode]);

  return (
    <form className="w-full p-7 bg-white rounded border leading-loose flex flex-col xl:flex-row xl:gap-5 h-fit">
      <div>
        <div className="w-full">
          <div className=" ">
            <label className="block text-gray-00">
              {t("civility")} <span className="text-red-500">*</span>
            </label>
            <Field
              name="civility"
              type="text"
              component="select"
              className="px-1 pr-2 border rounded placeholder:text-sm py-1 cursor-pointer"
            >
              <option value="" disabled>
                {t("choose_civility")}
              </option>
              <option value="mr"> {t("mr")}</option>
              <option value="mme"> {t("mrs")}</option>
            </Field>
          </div>
          <div className=" ">
            <label className="block text-gray-00">
              {t("last_name")} <span className="text-red-500">*</span>
            </label>
            <Field
              name="lastName"
              type="text"
              component={renderField}
              placeholder={t("last_name")}
              className="w-full px-2 border rounded placeholder:text-sm"
            />
          </div>
          <div className="mt-2">
            <label className="block text-gray-00">
              {t("first_name")} <span className="text-red-500">*</span>
            </label>
            <Field
              name="firstName"
              type="text"
              component={renderField}
              placeholder={t("first_name")}
              className="w-full px-2 border rounded placeholder:text-sm"
            />
          </div>
          <div className="mt-2">
            <label className="block">
              {t("email")} <span className="text-red-500">*</span>
            </label>
            <Field
              name="email"
              type="text"
              component={renderField}
              placeholder={t("email")}
              className="w-full px-2 border rounded placeholder:text-sm"
            />
          </div>
          <div className="mt-2 relative ">
            <label className="block">
              {t("phone")} <span className="text-red-500">*</span>
            </label>

            {/* <PhoneInput
              country={"fr"}
              specialLabel={""}
              value={phoneNumber}
              onChange={(val) => {
                setPhoneNumber(val);
                dispatch(setPhone(val));
              }}
              preferredCountries={["fr", "es", "de", "gb"]}
              className="w-full"
            /> */}
            <Field name="phone" type="number" component={PhoneCustomInput} />
          </div>
        </div>

        <div className="w-full flex flex-col ">
          <div className="mt-2 xl:mt-0">
            <label className=" block">
              {t("address")} <span className="text-red-500">*</span>
            </label>
            <Field
              name="street"
              type="text"
              component={renderField}
              placeholder={t("street")}
              className="w-full px-2 border rounded placeholder:text-sm"
            />
          </div>
          <div className="mt-2">
            <label className="hidden">{t("city")}</label>
            <Field
              name="city"
              type="text"
              component={renderField}
              placeholder={t("city")}
              className="w-full px-2 border rounded placeholder:text-sm"
            />
          </div>
          <div className="flex items-start">
            <div className="inline-block mt-2  w-1/2">
              <label className="hidden">{t("zip_code")}</label>

              <Field
                name="zipCode"
                type="number"
                component={renderField}
                placeholder={t("zip_code")}
                className="w-full px-2 border rounded placeholder:text-sm"
              />
            </div>
            <div className="inline-block mt-2 w-1/2 ">
              <select
                defaultValue={countryCode ? countryCode : defaultOption.code}
                onChange={(e) => {
                  setCountryCode(e.target.value);
                  dispatch(setCountry(e.target.value));
                }}
                className="border w-full py-[5px] rounded px-2 hover:bg-slate-50 cursor-pointer ml-1"
              >
                {countries.map((country, i) => (
                  <option key={i} value={country.code}>
                    {country.name}
                  </option>
                ))}
                <option></option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`flex flex-col w-full ${
            location.pathname.includes("restaurant/personal-information") &&
            "hidden"
          }`}
        >
          <label>{t("comments")}</label>
          <Field
            name="comment"
            type="textarea"
            component="textarea"
            placeholder={t("comments_placeholder_form")}
            className="placeholder:leading-none placeholder:pt-2 border rounded w-full pl-2"
            rows="3"
            cols="60"
          />
          <span className="leading-none mt-1 text-sm  text-gray-500">
            {t("form_info_warning")}
          </span>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "syncValidation",
  validate,
})(SyncValidationForm);
