import React from "react";
import { Outlet, useLocation } from "react-router";
import RoomsBreadcrumb from "../RoomsBreadcrumb";
import HotelBasket from "../HotelBasket";
import { useTranslation } from "react-i18next";

function AvailabilitesLayout() {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div className="bg-slate-50 ">
      <div
        className={` ${
          location.pathname.includes("availabilities") ? "" : "hidden"
        }`}
      >
        <RoomsBreadcrumb />
      </div>
      <h3
        className={`border-b w-fit border-gray-300 pr-3 ml-7 2xl:mx-20 pt-7 text-xl font-semibold ${
          location.pathname.includes("availabilities") && "hidden"
        }`}
      >
        {(location.pathname.includes("restaurant") && `${t("resto_title")}`) ||
          (location.pathname.includes("personal-information") &&
            `${t("form_title")}`) ||
          (location.pathname.includes("payment") && `${t("payment_title")}`)}
      </h3>

      <div className="flex flex-col md:flex md:flex-row justify-between p-7 gap-6 bg-slate-50 2xl:px-20 min-h-[63vh]">
        <div className="w-full ">
          <Outlet />
        </div>
        <div className="w-fit max-md:self-center">
          <HotelBasket />
        </div>
      </div>
    </div>
  );
}

export default AvailabilitesLayout;
