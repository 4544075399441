import React, { useEffect } from "react";
import HomeItem from "./HomeItem/HomeItem";
import useFetchData from "../../hooks/useFetchData";
import { setAppSettings } from "../../store/slices/settingsSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../components/LanguageDropdown";
import useLangPath from "../../hooks/useLangPath";

function Home() {
  const createPath = useLangPath();
  const dispatch = useDispatch();
  const { data } = useFetchData(`/company/settings`);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setAppSettings(data));
  }, [data, dispatch]);


  return (
    <>
      <LanguageDropdown />
      <div className="w-full min-h-screen backdrop-blur-[1px] flex justify-center">
        <img
          src={data.logo}
          className="w-[200px] h-auto md:w-[300px] mx-auto py-16 absolute z-10 max-md:top-[39%]"
          alt="logo"
          loading="lazy"
        />
        <div className="w-full flex flex-col md:flex-row ">
          <HomeItem
            title={t("title_hotel")}
            path={createPath("hotel")}
            image={data.cover_image}
          />
          <HomeItem
            title={t("title_restaurant")}
            path={createPath("restaurant")}
            image={data.cover_image_2}
          />
        </div>
      </div>
    </>
  );
}

export default Home;
