import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  availabilities: [],
};

export const availabilitiesSlice = createSlice({
  name: "availabilities",
  initialState,
  reducers: {
    setAvailabilities: (state, action) => {
      state.availabilities = action.payload;
    },
    updateQuantity: (state, action) => {
      state.availabilities.map((room) => {
        room.quantities.forEach((item) => {
          action.payload.rooms.forEach((r) => {
            action.payload.updatedRooms.forEach((ro) => {
              if (
                item.quantity !== 0 &&
                room.code === r.room_type &&
                action.payload.rooms.length > 1
              ) {
                return {
                  ...state,
                  availabilities: {
                    ...room,
                    quantity: (item.quantity = item.quantity - 1),
                  },
                };
              } else if (
                room.code !== r.room_type &&
                action.payload.rooms.length > 1 &&
                ro.code === room.code
              ) {
                ro.quantities.forEach((q) => {
                  return {
                    ...state,
                    availabilities: {
                      ...room,
                      quantity: (item.quantity = q.quantity),
                    },
                  };
                });
              }
            });
          });
        });
      });
    },
    resetRoomsState: (state = initialState, action) => {
      return initialState;
    },
  },
});

export const availabilitiesReducer = availabilitiesSlice.reducer;
export const { setAvailabilities, updateQuantity, resetRoomsState } =
  availabilitiesSlice.actions;
