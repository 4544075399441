import { useLocation } from "react-router-dom";

function useLangPath() {
  const location = useLocation();

  const segments = location.pathname.split("/");
  const langCode = segments[1];

  const createPath = (path) => {
    if (path.startsWith(`/${langCode}/`)) {
      return path;
    }
    return `/${langCode}${path.startsWith("/") ? "" : "/"}${path}`;
  };

  return createPath;
}

export default useLangPath;
