import React from "react";
import { Outlet } from "react-router";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import RestaurantBasket from "../RestaurantBasket/RestaurantBasket";
import MainBreadcrumb from "../MainBreadcrumb/MainBreadcrumb";
import { useTranslation } from "react-i18next";
import useLangPath from "../../hooks/useLangPath";

function RestaurantLayout() {
  const createPath = useLangPath();
  const { t } = useTranslation();

  const icon = <span>\</span>;
  const links = [
    {
      name: `${t("dates")}`,
      path: createPath("/restaurant"),
      icon: icon,
      end: true,
    },
    {
      name: `${t("information")}`,
      path: createPath("/restaurant/personal-information"),
      icon: icon,
    },
    { name: `${t("validation")}`, path: createPath("/restaurant/payment") },
  ];

  return (
    <div className="min-h-screen bg-slate-50">
      <Header />
      <div className="pl-5 border-b bg-white">
        <MainBreadcrumb links={links} />
      </div>
      <div className="grid md:grid-cols-3 md:p-5 md:gap-3">
        <Outlet />
        <RestaurantBasket />
      </div>
      <Footer />
    </div>
  );
}

export default RestaurantLayout;
