import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function SuccessView() {
  sessionStorage.clear();
  const { t } = useTranslation();

  return (
    <div className="flex items-center text-center  flex-col min-h-screen justify-center px-10">
      <FontAwesomeIcon
        icon={faCheck}
        className="text-5xl text-green-600 py-2"
      />

      <h2 className="text-2xl font-bold">{t("success_title")}</h2>
      <p className="my-6 text-xl leading-none font-semibold ">
        {t("success_subtitle")}
      </p>
      <p className="mb-2">{t("success_paragraph")}</p>
      <a href="tel:+33388719898" className="underline text-primary">
        +33 (0) 388719898
      </a>
      <span>{t("success_email")}</span>
      <a
        href="mailto: reservation@villarenelalique.com"
        className="underline text-primary"
      >
        {" "}
        reservation@villarenelalique.com
      </a>
      <p className="italic mt-5">{t("success_footer")}</p>
    </div>
  );
}

export default SuccessView;
