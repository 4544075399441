import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import notFound from "../../assets/2.png";
import { setErrorMsg } from "../../store/slices/bookingParamSlice";

function NotFound() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const errorMsg = useSelector((state) => state.bookingParam.errorMsg);

  useEffect(() => {
    dispatch(setErrorMsg(""));
  }, [pathname]);

  return (
    <>
      <div className="">
        <Header />
        <div className="container m-auto grid lg:grid-cols-2 items-center  lg:gap-5  max-lg:mt-40 lg:h-[79vh]">
          <div className="flex flex-col justify-center items-center gap-7 max-md:pb-16">
            <p className="text-6xl font-semibold">Page 404</p>

            <div className="flex flex-col justify-center items-center gap-3">
              <span className="text-xl text-gray-500 italic">
                {errorMsg ? errorMsg : "Something went wrong..."}
              </span>
              <Link
                to={"/"}
                className="border p-2 border-secondary rounded-lg bg-secondary hover:bg-slate-700 hover:border-slate-700 text-white "
              >
                {`Go back to the booking page`}
              </Link>
            </div>
          </div>
          <div className="md:max-lg:p-24">
            <img src={notFound} alt="error" className="lg:w-[700px]" />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default NotFound;
