import axios from "axios";
// const BASE_URL = "https://mbooking.eu.aldryn.io/api"; // Prod
// const BASE_URL = "https://mbooking-stage.eu.aldryn.io/api";
const BASE_URL = "%REACT_APP_MBOOKING_API_URL%";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key":
      "rvE4fE3Z.zSCdn2KIAdIWeAL9Zx0eHehG6kWOwZsMW0BeaqidKea7XB7iHAowrBir",
  },
});

export default axiosInstance;
