import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import axios from "../../api/axios";
import {
  setEmail,
  setOrderId,
  setReservationType,
  setTotalAmountToBlock,
} from "../../store/slices/bookingParamSlice";
import {
  faAngleDown,
  faAngleUp,
  faCalendarDays,
  faClock,
  faGift,
  faUsers,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setState } from "../../store/slices/restaurantSlice";
import { useTranslation } from "react-i18next";
import useLangPath from "../../hooks/useLangPath";

function RestaurantBasket() {
  const createPath = useLangPath();
  const tableBooking = useSelector((state) => state.restaurant);
  const { country, totalAdults, rooms, totalNights, reservationType } =
    useSelector((state) => state.bookingParam);
  const [totalAmount, setTotalAmount] = useState();
  const [disableBtn, setDisableBtn] = useState(false);
  const selectedLang = useSelector((state) => state.language.selectedLang);
  const form = useSelector((state) => state.form);
  const [showBasket, setShowBasket] = useState(false);
  const hotelSettings = useSelector((state) => state.settings.hotelSettings);
  const restaurant = useSelector(
    (state) => state.restaurantHotel.restaurantBooking
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      pathname.includes("personal-information") &&
      form.syncValidation?.hasOwnProperty("syncErrors")
    ) {
      setDisableBtn(true);
    } else if (
      pathname.includes("/restaurant") &&
      tableBooking.date.length === 0 &&
      tableBooking.hours.length === 0
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [pathname, form, tableBooking]);

  useEffect(() => {
    if (reservationType === "R") {
      setTotalAmount(totalAdults * hotelSettings.secure_restaurant_amount);
    } else {
      setTotalAmount(600 * rooms.length);
    }
  }, [
    totalAdults,
    restaurant,
    hotelSettings,
    reservationType,
    totalNights,
    dispatch,
    rooms,
  ]);

  useEffect(() => {
    totalAmount && dispatch(setTotalAmountToBlock(totalAmount));
  }, [totalAmount, reservationType, totalNights, dispatch]);

  const createBooking = async () => {
    if (form.syncValidation.values) {
      dispatch(setEmail(form.syncValidation.values.email));
      await axios
        .post("/restaurant/reservation/create", {
          reference: "",
          limit_date: "",
          first_name: form.syncValidation.values.firstName.toUpperCase(),
          last_name: form.syncValidation.values.lastName.toUpperCase(),
          email: form.syncValidation.values.email,
          phone: form.syncValidation.values.phone,
          street: form.syncValidation.values.street,
          city: form.syncValidation.values.city.toUpperCase(),
          zipcode: form.syncValidation.values.zipCode,
          gender: form.syncValidation.values.civility.toUpperCase(),
          country: country,
          lang: selectedLang,
          date: tableBooking.date,
          service: tableBooking.service,
          adults: tableBooking.adults,
          children: 0,
          comments: tableBooking.comments,
          hours: tableBooking.hours,
          gift_code: tableBooking.gift_code,
          gift_amount: tableBooking.gift_amount,
          menu: tableBooking.menu,
          code: tableBooking.code,
        })
        .then((data) => {
          dispatch(setOrderId(data.data.orderId));
          dispatch(setReservationType("R"));
          navigate(createPath("/restaurant/payment"));
        })
        .catch((error) => console.log(error));
    }
  };

  const handleContinueBtn = () => {
    if (pathname.includes("/restaurant/personal-information")) {
      createBooking();
    } else {
      navigate(createPath("/restaurant/personal-information"));
    }
  };

  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const handleChangeBtn = () => {
    dispatch(setState());
    navigate(createPath("/restaurant"));
  };

  function toLocaleUTCDateString(date, locales, options) {
    const timeDiff = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.valueOf() + timeDiff);
    return adjustedDate.toLocaleDateString(locales, options);
  }

  return (
    <div
      className={`flex flex-col border rounded h-fit py-4 max-md:px-3 max-sm:mt-5 bg-white max-md:fixed max-md:bottom-0 max-md:w-full max-md:left-0 min-w-[300px] z-20  `}
    >
      <div className="mb-3 text-lg md:px-4 border-b pb-2 flex justify-between">
        <h1 className="font-semibold text-center">{t("resto_title")}</h1>
        <button
          onClick={handleChangeBtn}
          className={`text-primary underline text-base hover:text-opacity-75 duration-300  ${
            pathname.includes("/restaurant/personal-information")
              ? "visible"
              : "hidden"
          } `}
        >
          {t("change")}
        </button>
        <button
          className="md:hidden text-sm  flex gap-1 items-center text-sky-600  hover:text-sky-700"
          onClick={() => setShowBasket((prevState) => !prevState)}
        >
          {showBasket
            ? `${t("hide_details_basket")}`
            : `${t("show_details_basket")}`}
          {showBasket ? (
            <FontAwesomeIcon icon={faAngleDown} />
          ) : (
            <FontAwesomeIcon icon={faAngleUp} />
          )}
        </button>
      </div>

      <div
        className={`border-b divide-y mb-3 ${
          showBasket ? "" : "max-md:hidden"
        }`}
      >
        <ul className="px-4 py-2">
          <li className="flex justify-between">
            <div>
              <FontAwesomeIcon icon={faCalendarDays} className="pr-2 w-5" />
              <span>
                {t("date")}
                {selectedLang === "fr" ? " :" : ":"}
              </span>
            </div>
            <span className="font-semibold">
              {toLocaleUTCDateString(
                new Date(tableBooking.date),
                `${
                  selectedLang === "fr"
                    ? "fr-FR"
                    : selectedLang === "en"
                    ? "en-US"
                    : "de-DE"
                }`,
                options
              ) !== "Invalid Date"
                ? toLocaleUTCDateString(
                    new Date(tableBooking.date),
                    `${
                      selectedLang === "fr"
                        ? "fr-FR"
                        : selectedLang === "en"
                        ? "en-US"
                        : "de-DE"
                    }`,
                    options
                  )
                : null}
            </span>
          </li>
          <li className="flex justify-between">
            <div>
              <FontAwesomeIcon icon={faClock} className="pr-2 w-5" />
              <span>
                {t("hour")}
                {selectedLang === "fr" ? " :" : ":"}
              </span>
            </div>
            <span className="font-semibold">{tableBooking.hours}</span>
          </li>
          <li className="flex justify-between">
            <div>
              <FontAwesomeIcon icon={faUsers} className="pr-2 w-5" />
              <span>
                {t("nb_person")}
                {selectedLang === "fr" ? " :" : ":"}
              </span>
            </div>
            <span className="font-semibold">
              {tableBooking.adults > 0
                ? `${tableBooking.adults} ${t("adults")}`
                : null}
            </span>
          </li>
          <li className="flex justify-between">
            <div>
              <FontAwesomeIcon icon={faUtensils} className="pr-2 w-5" />
              <span>
                {t("menu")}
                {selectedLang === "fr" ? " :" : ":"}
              </span>
            </div>
            <span className="font-semibold">
              {" "}
              {!tableBooking.menu?.length && tableBooking.date.length
                ? `${t("menu_choice")}`
                : tableBooking.menu}
            </span>
          </li>
        </ul>

        <div className="p-5 text-gray-700 flex flex-col gap-3">
          <FontAwesomeIcon icon={faGift} className="mr-2 text-xl" />
          <p className="text-center">{t("gift_voucher_qst")}</p>
        </div>

        <p className="md:mt-3 md:px-4 py-2 font-semibold">
          {t("not_payment_cheque")}
        </p>
      </div>

      <button
        className={`duration-300 bg-primary rounded-md px-3 py-2 w-full max-md:text-lg lg:text-xl md:w-fit cursor-pointer text-white md:mt-4 self-center ${
          pathname.includes("/payment") && "hidden"
        }
        ${
          disableBtn
            ? "opacity-30 cursor-default pointer-events-none"
            : "hover:bg-secondary"
        }
      `}
        onClick={handleContinueBtn}
      >
        {t("continue")}
      </button>
    </div>
  );
}

export default RestaurantBasket;
