import { faAngleDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

function MenusDropdown(props) {
  const { options, value, onChange, selectedDate } = props;
  const [isOpen, setIsOpen] = useState(false);

  const selectOption = (option) => {
    onChange(option);
  };

  const isOptionSelected = (option) => {
    return option === value;
  };

  useEffect(() => {
    clearOptions();
  }, [selectedDate]);

  const clearOptions = () => {
    onChange({ name: "" });
  };

  return (
    <div
      onBlur={() => setIsOpen(false)}
      onClick={() => setIsOpen((prevState) => !prevState)}
      tabIndex={0}
      className="border  rounded-md py-1  px-1 relative w-full cursor-pointer"
    >
      <div className="flex items-center justify-between">
        <div className="group hover:text-primary">
          <FontAwesomeIcon icon={faAngleDown} />
          <span className="pl-2 ">
            {value.name.length ? value.name : "Menus"}
          </span>
        </div>

        <button
          onClick={(e) => {
            e.stopPropagation();
            clearOptions();
          }}
        >
          <FontAwesomeIcon icon={faXmark} className="mr-2 hover:text-red-700" />
        </button>
      </div>
      <ul
        className={`${
          isOpen
            ? "divide-y absolute z-10 bg-white w-full border right-0 py-2 px-1"
            : "hidden"
        }`}
      >
        {options.map((option, i) => {
          return (
            <li
              value={option.name}
              key={i}
              onClick={(e) => {
                e.stopPropagation();
                selectOption(option);
                setIsOpen(false);
              }}
              className={`py-1 hover:text-primary rounded pl-2 ${
                isOptionSelected(option)
                  ? "bg-primary text-white hover:text-white"
                  : ""
              }`}
            >
              {option.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default MenusDropdown;
