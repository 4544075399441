import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addOptionsToBasket,
  addRoomToBasket,
} from "../../../store/slices/bookingParamSlice";
import "reactjs-popup/dist/index.css";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

function AvailabilitiesItem({ room, rooms, disabledRoomArr }) {
  const {
    code,
    capacity,
    night_price,
    total_price,
    services,
    no_nights,
    prices,
    name,
    max_capacity,
  } = room;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState("code");
  const [breakfast, setBreakfast] = useState({});
  const [breakfastAdded, setBreakfastAdded] = useState(false);
  const [highCapacity, setHighCapacity] = useState(null);
  const { roomIndex } = useSelector((state) => state.bookingParam);
  const selectedLang = useSelector((state) => state.language.selectedLang);
  const [suiteIndex, setSuiteIndex] = useState();
  const hotelSettings = useSelector((state) => state.settings.hotelSettings);
  const options = useSelector((state) => state.options.options);
  const breakfastIncluded = hotelSettings.auto_add_breakfast;

  useEffect(() => {
    options?.map((item) => {
      if (item.code === "PDJ" && item.active) {
        setBreakfast(item);
      } else {
        return null;
      }
    });

    rooms[roomIndex].options.map((option) => {
      if (option.code === breakfast.code) {
        setBreakfastAdded(true);
      }
    });
  }, [options, rooms, selectedRoom]);

  useEffect(() => {
    rooms.forEach((room, i) => {
      room.room_type === code && setSuiteIndex(i + 1);
      room.adults > 2 && setHighCapacity(room.adults);
    });
  }, [rooms, code]);

  const handleBtn = () => {
    dispatch(
      addRoomToBasket({
        name,
        roomIndex,
        code,
        total_price,
        night_price,
        prices: prices,
      })
    );

    setSelectedRoom(code);

    if (breakfastIncluded && !breakfastAdded) {
      dispatch(
        addOptionsToBasket({
          quantity: rooms[roomIndex].adults,
          unit_price: breakfast.unit_price,
          code: breakfast.code,
          entire_stay: breakfast.entire_stay,
          name: breakfast.name_fr,
          name_fr: breakfast.name_fr,
          name_en: breakfast.name_en,
          name_de: breakfast.name_de,
        })
      );
    } else {
      return null;
    }
  };
  const [open, setOpen] = React.useState(false);

  return (
    <div
      className={`border-b p-4 sm:pr-5 border rounded-md bg-white h-fit ${
        rooms[roomIndex]?.room_type === selectedRoom
          ? "border-b p-4 sm:pr-5 border rounded-md bg-white h-fit"
          : disabledRoomArr.includes(room.code)
          ? "filter grayscale pointer-events-none "
          : ""
      }`}
    >
      <div className="flex flex-col  gap-3 2xl:flex-col ">
        <div>
          <>
            <button type="button" onClick={() => setOpen(true)}>
              <img
                src={room.main_image}
                alt="room"
                className="rounded hover:opacity-90 duration-300"
              />
            </button>

            <Lightbox
              open={open}
              close={() => setOpen(false)}
              plugins={[Thumbnails]}
              slides={room.gallery.map((item) => ({ src: item }))}
              styles={{
                container: { backgroundColor: "rgba(0, 0, 0, .8)" },
                slide: { borderRadius: "40px", border: "transparent" },
              }}
            />
          </>
        </div>
        <div className="flex flex-col flex-1 justify-between ">
          <div className="self-start">
            <div>
              <span>Suite </span>
              <span className="font-semibold">« {name} »</span>
            </div>

            <div>
              {services?.map((service, i) => {
                return (
                  <div className="inline-flex py-2" key={i}>
                    <div
                      className="bg-slate-100 rounded-md text-xs sm:text-sm px-2 py-1 mr-2 lg:mt-2 shadow-sm"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={
                        selectedLang === "fr"
                          ? service.description_fr
                          : selectedLang === "en"
                          ? service.description_en
                          : service.description_de
                      }
                    >
                      {service.name === "Animaux" ? (
                        <div className="relative">
                          <FontAwesomeIcon
                            icon={["fas", `${service.icon}`]}
                            className=""
                          />
                          <div className="">
                            <span className="border-b-2 border-red-600 origin-right rotate-45 w-6 absolute top-2/5 ml-[-8px] mt-[-2px] "></span>
                          </div>
                        </div>
                      ) : (
                        <FontAwesomeIcon
                          icon={["fas", `${service.icon}`]}
                          className={`${service.icon === "fa-dog" ? "" : ""}`}
                        />
                      )}
                    </div>
                    <Tooltip id="my-tooltip" />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex flex-col gap-2 md:flex-row justify-between md:max-lg:gap-5 2xl:flex-col">
            <div className="flex flex-col justify-end">
              <span className="text-sm italic">
                {no_nights} {t("night")},{" "}
                {max_capacity > 2 && highCapacity > 2
                  ? highCapacity
                  : max_capacity > 2
                  ? capacity
                  : max_capacity}{" "}
                {t("person")}
              </span>
              <span className="text-sm">
                {t("total_night")} {night_price.toFixed(2)} €
              </span>
              <span className="font-semibold">
                {t("total_stay")} {total_price.toFixed(2)} €
              </span>
            </div>
            <div className="flex flex-col xl:flex-row-reverse xl:gap-2 items-end justify-end 2xl:flex-col">
              <button
                className={`rounded w-full py-2 md:w-fit md:py-2 px-3 2xl:w-full ${
                  rooms[roomIndex]?.room_type === selectedRoom
                    ? "text-primary border border-primary pointer-events-none"
                    : !disabledRoomArr.includes(room.code)
                    ? "bg-secondary border border-black hover:bg-primary hover:border-primary duration-300 text-white "
                    : "border pointer-events-none "
                }`}
                onClick={handleBtn}
              >
                {rooms[roomIndex]?.room_type === selectedRoom
                  ? `${t("room_selected")}`
                  : !disabledRoomArr.includes(room.code)
                  ? `${t("room_select")}`
                  : `${t("room_unavailable", { suiteIndex: suiteIndex })}`}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm bg-slate-50 mt-2 rounded border p-1">
          {showMore
            ? `${
                selectedLang === "fr"
                  ? room.description_fr
                  : selectedLang === "en"
                  ? room.description_en
                  : room.description_de
              }`
            : `${
                selectedLang === "fr"
                  ? `${room.description_fr.substring(0, 120)}...`
                  : selectedLang === "en"
                  ? `${room.description_en.substring(0, 120)}...`
                  : `${room.description_de.substring(0, 120)}...`
              }`}
          <button
            className="font-semibold text-sm ml-1 hover:opacity-80 text-primary"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? `${t("see_less")}` : `${t("see_more")}`}
          </button>
        </p>
      </div>
    </div>
  );
}

library.add(fas);

export default AvailabilitiesItem;
