import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { setRoomIndex } from "../../store/slices/bookingParamSlice";
import { useTranslation } from "react-i18next";
import useLangPath from "../../hooks/useLangPath";

function RoomsBreadcrumb() {
  const createPath = useLangPath();
  const { t } = useTranslation();
  const { rooms, roomIndex } = useSelector((state) => state.bookingParam);
  const dispatch = useDispatch();

  return (
    <div className="">
      {rooms.length > 1 && (
        <div className="flex gap-2 justify-center sm:justify-start px-7 py-4 border-b bg-slate-50">
          {rooms.map((room, index) => (
            <button
              className={`border-2 bg-white rounded-xl px-2 py-1   ${
                index === roomIndex
                  ? "border-primary text-primary font-semibold cursor-default"
                  : "hover:bg-slate-100"
              }`}
              key={index}
              onClick={() => dispatch(setRoomIndex(index))}
            >
              {t("room")} {index + 1}
            </button>
          ))}
        </div>
      )}

      <div className=" text-center  flex flex-col items-center justify-center gap-2 border-b bg-white">
        <ul className="flex  items-start gap-2 flex-row text-sm sm:text-base p-7">
          <li>
            <NavLink
              to={createPath("/hotel/availabilities/rooms")}
              className={`flex flex-row gap-2 items-center ${({ isActive }) =>
                isActive ? "text-primary" : ""}`}
            >
              <button className="bg-primary px-2 rounded-full border border-primary font-semibold text-white">
                1
              </button>
              <span className="font-semibold"> {t("room")}</span>
              <span className="w-8 sm:w-36 ">
                <hr></hr>
              </span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={createPath("/hotel/availabilities/addons")}
              className={({ isActive }) =>
                isActive
                  ? "flex flex-row gap-2 border-primary font-semibold [&>*:first-child]:bg-primary [&>*:first-child]:text-white pointer-events-none"
                  : " pointer-events-none opacity-70 flex flex-row gap-2 sm:ml-2"
              }
            >
              <button className="bg-white border px-2 rounded-full text-primary">
                2
              </button>
              <span>Extra</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default RoomsBreadcrumb;
