import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  restaurantBooking: [],
  gift_code: "",
  gift_amount: 0,
};

export const restaurantHotelSlice = createSlice({
  name: "restaurantHotel",
  initialState,
  reducers: {
    addTable: (state, action) => {
      const tableInBasket = state.restaurantBooking.find(
        (table) => table.date === action.payload.date
      );

      if (tableInBasket) {
        tableInBasket.adults = action.payload.adults;
        tableInBasket.hours = action.payload.hours;
        tableInBasket.adults = action.payload.adults;
        tableInBasket.children = 0;
        tableInBasket.comments = action.payload.comments;
        tableInBasket.gift_code = state.gift_code;
        tableInBasket.gift_amount = state.gift_amount;
        tableInBasket.menu = action.payload.menu;
        tableInBasket.code = action.payload.code;
      } else {
        state.restaurantBooking.push({
          date: action.payload.date,
          service: "S",
          hours: action.payload.hours,
          adults: action.payload.adults,
          children: 0,
          comments: action.payload.comments,
          gift_code: state.gift_code,
          gift_amount: state.gift_amount,
          menu: action.payload.menu,
          code: action.payload.code,
        });
      }
    },

    setGiftVoucherR: (state, action) => {
      state.gift_code = action.payload.gift_code;
      state.gift_amount = action.payload.gift_amount;
      state.expiration_date = action.payload.expiration_date;
      state.voucher_type = action.payload.voucher_type;
      state.active = action.payload.active;
      // state.restaurantBooking[0] = {
      //   ...state.restaurantBooking,
      //   gift_code: action.payload.gift_code,
      //   gift_amount: action.payload.gift_amount,
      // };
    },
    resetRestaurantState: (state = initialState, action) => {
      return initialState;
    },

    removeTable: (state, action) => {
      let tableIndex = state.restaurantBooking.findIndex(
        (table) => table.date === action.payload
      );
      let tempTables = state.restaurantBooking;
      tempTables.splice(tableIndex, 1);
    },
    setRestaurantBooking: (state, action) => {
      state.restaurantBooking = action.payload;
    },
  },
});

export const restaurantHotelReducer = restaurantHotelSlice.reducer;
export const {
  resetRestaurantState,
  setGiftVoucherR,
  addTable,
  removeTable,
  setRestaurantBooking,
} = restaurantHotelSlice.actions;
