import React from "react";
import { NavLink } from "react-router-dom";

function MainBreadcrumb({ links }) {
  return (
    <nav className="flex py-2 md:py-3 text-gray-700 text-sm md:text-base rounded border-transparent max-md:hidden">
      <ul className="inline-flex items-center gap-1">
        {links.map((item, i) => {
          return (
            <li
              key={i}
              className="flex flex-row items-center justify-center gap-1"
            >
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  isActive
                    ? "text-primary pointer-events-none"
                    : "pointer-events-none"
                }
                end
              >
                {item.name}
              </NavLink>
              <span className="text-xs opacity-60 ">{item.icon}</span>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default MainBreadcrumb;
