import React from "react";

function Spinner() {
  return (
    <div className="flex justify-center items-center h-full py-10 w-full">
      <div className="border-t-transparent border-solid animate-spin rounded-full border-primary border-4 h-16 w-16 "></div>
    </div>
  );
}

export default Spinner;