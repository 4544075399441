import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  options: [],
};

export const optionsSlice = createSlice({
  name: "options",
  initialState,
  reducers: {
    setOptions: (state, action) => {
      state.options = action.payload;
    },
    resetOptionsState: (state = initialState, action) => {
      return initialState;
    },
  },
});

export const optionsReducer = optionsSlice.reducer;
export const { setOptions, resetOptions } = optionsSlice.actions;
