import React from "react";
import MainBreadcrumb from "../MainBreadcrumb/MainBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resetState } from "../../store/slices/bookingParamSlice";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { resetRestaurantState } from "../../store/slices/restaurantHotelSlice";
import { resetRoomsState } from "../../store/slices/availabilitiesSlice";
import { useTranslation } from "react-i18next";
import OccupacyDetails from "./OccupacyDetails";
import { toLocaleUTCDateString } from "../../utils/dateFormat";
import useLangPath from "../../hooks/useLangPath";

function NavBar() {
  const createPath = useLangPath();
  const { t } = useTranslation();
  const { startDate, endDate } = useSelector((state) => state.bookingParam);
  const selectedLang = useSelector((state) => state.language.selectedLang);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangeSearch = () => {
    sessionStorage.clear();
    dispatch(resetState());
    dispatch(resetRestaurantState());
    dispatch(resetRoomsState());
    navigate(createPath("/hotel"));
  };

  const locales =
    selectedLang === "fr" ? "fr-FR" : selectedLang === "en" ? "en-US" : "de-DE";
  let formatedStartD = toLocaleUTCDateString(new Date(startDate), locales);
  let formatedEndD = toLocaleUTCDateString(new Date(endDate), locales);

  const icon = <span>\</span>;
  const links = [
    {
      name: `${t("dates")}`,
      path: createPath("/hotel"),
      icon: icon,
      end: true,
    },
    {
      name: `${t("availabilities")}`,
      path: createPath("/hotel/availabilities/rooms"),
      icon: icon,
    },
    {
      name: `${t("restaurant")}`,
      path: createPath("/hotel/restaurant"),
      icon: icon,
    },
    {
      name: `${t("information")}`,
      path: createPath("/hotel/personal-information"),
      icon: icon,
    },
    { name: `${t("validation")}`, path: createPath("/hotel/payment") },
  ];

  return (
    <div
      className={`flex py-2 border-b px-7 ${
        pathname === createPath("/hotel") || pathname === createPath("/hotel/")
          ? "flex-col gap-2 "
          : "flex-col lg:flex-row items-center justify-between"
      }`}
    >
      <div>
        <MainBreadcrumb links={links} />
      </div>

      {pathname === createPath("/hotel") ||
      pathname === createPath("/hotel/") ? (
        <div className="">
          <OccupacyDetails />
        </div>
      ) : (
        <div className="flex gap-1 md:gap-2 rounded-2xl text-sm">
          <div className="items-center flex gap-1 text-base font-semibold border-r pr-1 md:pr-2">
            <div className="items-center flex gap-1">
              <span className="text-base md:text-xl text-center">
                {formatedStartD}
              </span>
            </div>
            <span className="">{`> `}</span>
            <div className="items-center flex gap-1">
              <span className="text-base md:text-xl text-center">
                {formatedEndD}
              </span>
            </div>
          </div>

          <div className="relative group flex">
            <Popup
              trigger={
                <button className=" text-primary lg:text-base">
                  {" "}
                  {t("change_params")}
                </button>
              }
              contentStyle={{
                backgroundColor: "black",
                border: "transparent",
                borderRadius: "10px",
                color: "white",
              }}
              modal
            >
              {(close) => (
                <div className="p-5 md:p-7 flex flex-col gap-5">
                  <p className="text-center md:text-lg">
                    {t("change_params_popup")} <br></br>
                  </p>
                  <div className="flex  justify-center gap-3">
                    <button
                      className="close bg-slate-600 text-white  cursor-pointer rounded px-2 py-1 hover:bg-slate-800"
                      onClick={close}
                    >
                      {t("no")}
                    </button>
                    <button
                      className="bg-primary text-white cursor-pointer px-2 rounded hover:bg-white hover:text-secondary"
                      onClick={handleChangeSearch}
                    >
                      {t("yes")}
                    </button>
                  </div>
                </div>
              )}
            </Popup>

            <span className="absolute -bottom-[.1px] left-0 w-0 h-[1px] bg-primary transition-all duration-500 group-hover:w-full"></span>
          </div>
        </div>
      )}
    </div>
  );
}

export default NavBar;
