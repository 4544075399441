import React from "react";
import {
  incrementGuest,
  decrementGuest,
  decrementChildren,
  incrementChildren,
  removeRoom,
} from "../../../../store/slices/bookingParamSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

function OccupacyDetailsItem({ index, room, max_children }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col gap-2 max-sm:text-lg max-sm:px-8 border-b pb-3">
      <span className="font-semibold mt-3">
        {t("room")} {index + 1}
      </span>
      <div className="flex justify-between">
        <span>{t("adults")}</span>
        <div className="flex gap-2 items-center">
          <button
            disabled={room.adults <= 1}
            onClick={() => dispatch(decrementGuest(index))}
            className="bg-white px-2 border border-gray-800  rounded-full font-medium disabled:text-gray-300 disabled:border-gray-300"
          >
            -
          </button>
          <span className="text-center">{room.adults}</span>
          <button
            onClick={() => dispatch(incrementGuest(index))}
            className="bg-white px-2 border-gray-800 border rounded-full font-medium disabled:text-gray-300 disabled:border-gray-300"
            disabled={room.adults >= 4}
          >
            +
          </button>
        </div>
      </div>
      <div className="flex justify-between">
        <span>{t("children")}</span>
        <div className="flex gap-2 items-center">
          <button
            disabled={room.adults < 4 || room.children <= 0}
            onClick={() => dispatch(decrementChildren(index))}
            className="bg-white px-2 border border-gray-800 rounded-full font-medium disabled:text-gray-300 disabled:border-gray-300"
          >
            -
          </button>
          <span>{room.adults < 4 ? 0 : room.children}</span>
          <button
            onClick={() => dispatch(incrementChildren(index))}
            className="bg-white px-2 border border-gray-800 rounded-full font-medium disabled:text-gray-300 disabled:border-gray-300"
            disabled={room.adults < 4 || room.children >= max_children}
          >
            +
          </button>
        </div>
      </div>

      {index > 0 ? (
        <button
          className="flex self-end text-red-700 hover:underline w-fit "
          onClick={() => dispatch(removeRoom(index))}
        >
          {t('delete')}
        </button>
      ) : null}
    </div>
  );
}

export default OccupacyDetailsItem;
