import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Datepicker,
  localeDe,
  localeEn,
  localeFr,
  setOptions,
} from "@mobiscroll/react";
import moment from 'moment-timezone';
import { momentTimezone } from '@mobiscroll/react';
momentTimezone.moment = moment;

function RestaurantCalendar({ restaurantData, handleDate, colors, min, max }) {
  const selectedLang = useSelector((state) => state.language.selectedLang);

  useEffect(() => {
    setOptions({
      theme: "ios",
      themeVariant: "light",
      locale:
        selectedLang === "fr"
          ? localeFr
          : selectedLang === "en"
          ? localeEn
          : localeDe,
    });
  }, [selectedLang]);
  
  return (
    <Datepicker
      timezonePlugin={momentTimezone}
      dataTimezone="utc"
      displayTimezone="Europe/Paris"
      display="inline"
      controls={["calendar"]}
      selectMultiple={false}
      colors={colors}
      calendarType="month"
      theme="my-theme"
      valid={restaurantData}
      onChange={handleDate}
      min={min}
      max={max}
      dateFormat="YYYY-MM-DD"
      className="w-full"
      responsive={{
        xsmall: {
          display: "inline",
          pages: 1,
        },
        medium: {
          display: "inline",
          pages: 2,
        },
        large: {
          display: "inline",
          pages: 2,
        },
        custom: {
          breakpoint: 600,
          pages: 2,
        },
      }}
    />
  );
}

export default RestaurantCalendar;
