import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import OccupacyDetailsItem from "./OccupacyDetailsItem";
import { useDispatch, useSelector } from "react-redux";
import { addRoom, resetState } from "../../../store/slices/bookingParamSlice";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { useTranslation } from "react-i18next";

function OccupacyDetails() {
  const { t } = useTranslation();
  const hotelSettings = useSelector((state) => state.settings.hotelSettings);
  const { rooms, totalAdults } = useSelector((state) => state.bookingParam);
  const dispatch = useDispatch();

  const handleAddRoom = () => {
    if (hotelSettings.max_rooms_per_booking > rooms.length) {
      dispatch(addRoom({ adults: 2, children: 0, options: [] }));
    }
  };

  const { ref, isComponentVisible, setIsComponentVisible } =
    useOutsideClick(false);

  return (
    <div className="flex items-center gap-1 text-sm md:text-base justify-center md:justify-start md:border-t pb-2 pt-3">
      <div className="sm:relative " ref={ref}>
        <div className="flex items-center gap-3">
          <FontAwesomeIcon icon={faUsers} className="text-4xl text-secondary" />
          <div className="relative group leading-none border-r pr-2">
            <span className="text-primary text-lg">{t("occupations")}</span>
            <button
              onClick={() => setIsComponentVisible((prevState) => !prevState)}
              className="text-primary flex gap-1 items-center "
            >
              {totalAdults} {t("person")}, {rooms.length} {t("room")}(s)
              {!isComponentVisible ? (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="mt-1 group-hover:translate-y-1 group-hover:duration-700 duration-700"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className="mb-[2px] self-end group-hover:-translate-y-1 group-hover:duration-700"
                />
              )}
            </button>
            <span className="absolute -bottom-[.2px] left-0 w-0 h-[1px] bg-primary transition-all duration-500 group-hover:w-full"></span>
          </div>
        </div>

        {isComponentVisible && (
          <div className=" absolute max-sm:left-0  sm:absolute z-10 bg-white p-4 mt-2 border rounded text-sm flex flex-col gap-2 shadow-xl right-0">
            {rooms.map((item, i) => (
              <OccupacyDetailsItem
                index={i}
                room={item}
                key={i}
                max_children={hotelSettings.max_children_per_room}
              />
            ))}
            <button
              className={`"hover:bg-opacity-100 max-sm:text-lg  rounded p-2 bg-primary bg-opacity-90 text-white shadow-md ${
                hotelSettings.max_rooms_per_booking <= rooms.length
                  ? "!bg-gray-400 pointer-events-none"
                  : ""
              }`}
              onClick={handleAddRoom}
            >
              {t("add_room")}
            </button>
          </div>
        )}
      </div>{" "}
      <button
        onClick={() => {
          dispatch(resetState());
          window.location.reload();
        }}
        className=" mx-2 relative group"
      >
        {t("reset")}
        <span className="absolute -bottom-[.2px] left-0 w-0 h-[1px] bg-primary duration-500 transition-all group-hover:w-full"></span>
      </button>
    </div>
  );
}

export default OccupacyDetails;
