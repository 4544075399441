const defaultOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  export function toLocaleUTCDateString(date, locales, options = defaultOptions) {
    const timeDiff = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.valueOf() + timeDiff);
    return adjustedDate.toLocaleDateString(locales, options);
  }