import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hotelSettings: {},
  appSettings: {},
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setAppSettings: (state, action) => {
      state.appSettings = action.payload;
    },
    setHotelSettings: (state, action) => {
      state.hotelSettings = action.payload;
    },
    removeItem: (state, action) => {
      const removeItem = state.basket.filter(
        (item) => item.code !== action.payload
      );
      state.basket = removeItem;
    },
    resetState: (state = initialState, action) => {
      return initialState;
    },
  },
});

export const settingsReducer = settingsSlice.reducer;
export const {
  addToBasket,
  incrementQuantity,
  decrementQuantity,
  removeItem,
  resetState,
  setAppSettings,
  setHotelSettings,
} = settingsSlice.actions;
