import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  street: "",
  city: "",
  zipcode: "",
  country: "",
  lang: "",
  date: "",
  service: "",
  adults: 0,
  children: 0,
  comments: "",
  hours: "",
  gift_code: "",
  gift_amount: 0,
  menu: "",
};

export const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  reducers: {
    addTable: (state, action) => {
      return {
        ...action.payload,
      };
    },
    addGiftVoucherRest: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setState: (state = initialState, action) => {
      return initialState;
    },
  },
});

export const restaurantReducer = restaurantSlice.reducer;
export const { addTable, addGiftVoucherRest, setState } =
  restaurantSlice.actions;
