import {
  Route,
  Navigate,
  useParams,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { useEffect } from "react";
import i18n from "../utils/i18n";
import Home from "../pages/Home";
import HotelRestaurantBooking from "../pages/HotelRestaurantBooking";
import Availabilities from "../pages/Availabilities";
import { useDispatch } from "react-redux";
import AppLayout from "../components/AppLayout";
import AvailabilitesLayout from "../components/AvailabilitesLayout";
import AddOns from "../pages/AddOns/";
import NotFound from "../pages/NotFound";
import IndependantPayment from "../pages/IndependantPayment";
import PersonalInfo from "../pages/PersonalInfo";
import Payment from "../pages/Payment/Payment";
import HotelDates from "../pages/HotelDates/HotelDates";
import RestaurantLayout from "../components/RestaurantLayout/RestaurantLayout";
import RestaurantDates from "../pages/RestaurantDates/RestaurantDates";
import SuccessView from "../pages/SuccessView/SuccessView";
import {
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { setSelectedLang } from "../store/slices/languageSlice";

function LanguageProvider() {
  const supportedLanguages = ["fr", "en", "de"];
  const { lang } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!supportedLanguages.includes(lang)) {
      navigate("/fr", { replace: true });
    } else {
      dispatch(setSelectedLang(lang));
      i18n.changeLanguage(lang);
    }
  }, [lang, dispatch, navigate]);

  return <Outlet />;
}
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<Navigate replace to="/fr" />} />
      <Route path=":lang" element={<LanguageProvider />}>
        <Route index element={<Home />} />
        <Route path="hotel" element={<AppLayout />}>
          <Route index element={<HotelDates />} />
          <Route element={<AvailabilitesLayout />}>
            <Route path="availabilities/rooms" element={<Availabilities />} />
            <Route path="availabilities/addons" element={<AddOns />} />
            <Route path="personal-information" element={<PersonalInfo />} />
            <Route path="payment" element={<Payment />} />
            <Route path="restaurant" element={<HotelRestaurantBooking />} />
          </Route>
        </Route>
        <Route path="restaurant" element={<RestaurantLayout />}>
          <Route index element={<RestaurantDates />} />
          <Route path="personal-information" element={<PersonalInfo />} />
          <Route path="payment" element={<Payment />} />
        </Route>
        <Route path="success" element={<SuccessView />} />
        <Route path="reservation/:uuid" element={<IndependantPayment />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
  )
);
