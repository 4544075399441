import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { bookingParamReducer } from "./slices/bookingParamSlice";
import { settingsReducer } from "./slices/settingsSlice";
import storage from "redux-persist/lib/storage/session";
import { reducer as formReducer } from "redux-form";
import { availabilitiesReducer } from "./slices/availabilitiesSlice";
import { restaurantHotelReducer } from "./slices/restaurantHotelSlice";
import { restaurantReducer } from "./slices/restaurantSlice";
import { optionsReducer } from "./slices/optionsSlice";
import { languageReducer } from "./slices/languageSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const reducers = combineReducers({
  settings: settingsReducer,
  bookingParam: bookingParamReducer,
  availabilities: availabilitiesReducer,
  form: formReducer,
  restaurantHotel: restaurantHotelReducer,
  restaurant: restaurantReducer,
  options: optionsReducer,
  language: languageReducer,
});

export const store = configureStore({
  reducer: persistReducer(
    {
      key: "root",
      storage,
    },
    reducers
  ),
  devTools: false,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    }),
});

export const persistor = persistStore(store);
