import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasketItem from "./BasketItem/BasketItem";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { removeTable } from "../../store/slices/restaurantHotelSlice";
import {
  setEmail,
  setErrorMsg,
  setOrderId,
  setReservationType,
  setRoomIndex,
  setTotalAmountToBlock,
} from "../../store/slices/bookingParamSlice";
import {
  faAngleDown,
  faAngleUp,
  faGift,
  faSleigh,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import NotFound from "../../pages/NotFound/NotFound";
import { useTranslation } from "react-i18next";
import { toLocaleUTCDateString } from "../../utils/dateFormat";
import useLangPath from "../../hooks/useLangPath";

function HotelBasket() {
  const createPath = useLangPath();
  const [disableBtn, setDisableBtn] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [giftRestaurant, setGiftRestaurant] = useState({});
  const [totalAmount, setTotalAmount] = useState();
  const form = useSelector((state) => state.form);
  const restaurant = useSelector(
    (state) => state.restaurantHotel.restaurantBooking
  );
  const {
    phone,
    totalPrice,
    roomIndex,
    totalAdults,
    rooms,
    endDate,
    startDate,
    giftVoucher,
    country,
    totalAmountToBlock,
    reservationType,
  } = useSelector((state) => state.bookingParam);
  const selectedLang = useSelector((state) => state.language.selectedLang);
  const restaurantHotel = useSelector(
    (state) => state.restaurantHotel.restaurantBooking
  );
  const { amount_tourism_tax } = useSelector(
    (state) => state.settings.hotelSettings
  );
  const hotelSettings = useSelector((state) => state.settings.hotelSettings);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // total nights calcule
  const oneDay = 24 * 60 * 60 * 1000;
  const firstDate = new Date(startDate);
  const secondDate = new Date(endDate);
  const totalNights = Math.round(Math.abs((firstDate - secondDate) / oneDay));

  let basketDates = [];
  restaurantHotel.map((item) => basketDates.push(item.date));

  useEffect(() => {
    if (
      (rooms[roomIndex].room_total_price === 0 ||
        !rooms[roomIndex].room_total_price) &&
      location.pathname.includes("/availabilities/rooms")
    ) {
      setDisableBtn(true);
    } else if (
      basketDates.length < 1 &&
      location.pathname.includes("restaurant")
    ) {
      setDisableBtn(true);
    } else if (
      location.pathname.includes("personal-information") &&
      form.syncValidation?.hasOwnProperty("syncErrors")
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [rooms, basketDates, location.pathname, roomIndex, form.syncValidation]);

  useEffect(() => {
    if (
      location.pathname.includes("restaurant") &&
      totalNights > restaurantHotel.length
    ) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [restaurantHotel, totalNights, location.pathname]);

  useEffect(() => {
    if (reservationType === "R") {
      setTotalAmount(totalAdults * hotelSettings.secure_restaurant_amount);
    } else {
      setTotalAmount(600 * rooms.length);
    }
  }, [
    totalAdults,
    restaurant,
    hotelSettings,
    reservationType,
    totalNights,
    dispatch,
    rooms,
  ]);

  useEffect(() => {
    totalAmount && dispatch(setTotalAmountToBlock(totalAmount));
  }, [totalAmount, reservationType, totalNights, dispatch]);

  const createBooking = async () => {
    if (form.syncValidation.values) {
      dispatch(setEmail(form.syncValidation.values.email));
      if (giftVoucher.giftVoucher?.voucher_type === "RESTAURANT") {
        setGiftRestaurant(giftVoucher.giftVoucher);
      }
      await axios
        .post("/hotel/reservation/create", {
          reference: "",
          limit_date: "",
          first_name: form.syncValidation.values.firstName.toUpperCase(),
          last_name: form.syncValidation.values.lastName.toUpperCase(),
          email: form.syncValidation.values.email,
          street: form.syncValidation.values.street,
          city: form.syncValidation.values.city.toUpperCase(),
          zipcode: form.syncValidation.values.zipCode,
          comments: form.syncValidation.values.comment,
          gender: form.syncValidation.values.civility.toUpperCase(),
          phone: form.syncValidation.values.phone,
          country: country,
          lang: selectedLang,
          check_in: startDate,
          check_out: endDate,
          gift_code: giftVoucher.giftVoucher?.gift_code,
          gift_amount: giftVoucher.giftVoucher?.gift_amount,
          rooms: rooms,
          reservations_restaurant: restaurantHotel,
        })
        .then((data) => {
          dispatch(setOrderId(data.data.orderId));
          dispatch(setReservationType("H"));
          dispatch(setTotalAmountToBlock(totalAmount));
          navigate(createPath("/hotel/payment"));
        })
        .catch((error) => {
          console.log(error);
          dispatch(setErrorMsg(error.message));
          return <NotFound />;
        });
    }
  };

  // continue btn handler

  const handleContinueBtn = () => {
    if (
      rooms[roomIndex]?.room_total_price !== null &&
      location.pathname.includes("/availabilities/rooms")
    ) {
      navigate(createPath("/hotel/availabilities/addons"));
    } else if (
      roomIndex === rooms.length - 1 &&
      location.pathname.includes("/availabilities/addons")
    ) {
      navigate(createPath("/hotel/restaurant"));
    } else if (
      roomIndex < rooms.length - 1 &&
      location.pathname.includes("/availabilities/addons")
    ) {
      dispatch(setRoomIndex(roomIndex + 1));
      navigate(createPath("/hotel/availabilities/rooms"));
    } else if (
      location.pathname.includes("personal-information") &&
      form.syncValidation.values.firstName &&
      form.syncValidation.values.lastName &&
      form.syncValidation.values.email
    ) {
      createBooking();
    } else if (
      location.pathname.includes("restaurant") &&
      totalNights <= restaurantHotel.length
    ) {
      navigate(createPath("/hotel/personal-information"));
    }
  };

  const handleContinuePopup = () => {
    navigate(createPath("/hotel/personal-information"));
  };

  var options = {
    timeZone: "Europe/Paris",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const locales =
    selectedLang === "fr" ? "fr-FR" : selectedLang === "en" ? "en-US" : "de-DE";

  let formatedStartD = toLocaleUTCDateString(new Date(startDate), locales);
  let formatedEndD = toLocaleUTCDateString(new Date(endDate), locales);

  return (
    <div
      className={` flex flex-col border rounded h-fit  py-4 max-md:px-3 max-sm:mt-5 lg:w-[350px] xl:w-[500px] 2xl:w-[700px] bg-white max-md:fixed max-md:bottom-0 max-md:w-full max-md:left-0 min-w-[300px] z-20 ${
        rooms?.length === 0 ? "max-md:hidden" : ""
      }`}
    >
      <div className="mb-3 text-lg md:px-4 border-b pb-2 flex justify-between">
        <h1 className="font-semibold text-left ">{t("your_stay")}</h1>
        <button
          onClick={() => setShowDetails((prevState) => !prevState)}
          className="md:hidden text-sm  flex gap-1 items-center text-sky-600  hover:text-sky-700"
        >
          {showDetails
            ? `${t("hide_details_basket")}`
            : `${t("show_details_basket")}`}
          {showDetails ? (
            <FontAwesomeIcon icon={faAngleDown} />
          ) : (
            <FontAwesomeIcon icon={faAngleUp} />
          )}
        </button>
      </div>

      <div className="mb-3 leading-tight text-sm  max-md:text-base">
        <div className={`${showDetails ? "" : "max-md:hidden"}`}>
          <div className="border-b pb-2 text-sm max-md:text-sm mb-3">
            <p className="px-4">{`${t("from")} ${formatedStartD} ${t(
              "to"
            )} ${formatedEndD}`}</p>
            <p className="px-4">
              {" "}
              {totalAdults} {t("person")}, {rooms.length} {t("room")}(s) ,{" "}
              {totalNights} {t("night")}
            </p>
          </div>
          <div className="border-b divide-y mb-3">
            <span className="px-4 text-base max-md:text-lg font-semibold ">
              {t("hotel")}
            </span>

            {rooms[roomIndex]?.room_total_price === 0 ? (
              <p className="flex justify-center py-5 text-gray-500">
                {t("select_room_continue")}
              </p>
            ) : (
              <>
                {rooms.map((item, index) => (
                  <BasketItem
                    item={item}
                    index={index}
                    key={index}
                    rooms={rooms}
                    totalNights={totalNights}
                    amount_tourism_tax={amount_tourism_tax}
                  />
                ))}
              </>
            )}

            {(restaurantHotel.length &&
              location.pathname.includes("restaurant")) ||
            location.pathname.includes("personal-information") ||
            location.pathname.includes("payment") ? (
              <div>
                <div className="border-b divide-y mt-2">
                  <span className="px-4 text-base max-md:text-lg font-semibold">
                    {t("restaurant")}
                  </span>
                </div>
                {restaurantHotel?.map((table, i) => {
                  return (
                    <ul className="px-4 py-2" key={i}>
                      <li className="flex justify-between">
                        <span>
                          {t("date")}
                          {selectedLang === "fr" ? " :" : ":"}
                        </span>
                        <span className="font-semibold">
                          {new Date(table.date).toLocaleDateString(
                            `${
                              selectedLang === "fr"
                                ? "fr-FR"
                                : selectedLang === "en"
                                ? "en-US"
                                : "de-DE"
                            }`,
                            options
                          )}
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span>
                          {" "}
                          {t("nb_person")}
                          {selectedLang === "fr" ? " :" : ":"}
                        </span>
                        <span className="font-semibold">
                          {table.adults} {t("adults")}
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span>
                          {t("hour")}
                          {selectedLang === "fr" ? " :" : ":"}
                        </span>
                        <span className="font-semibold">{table.hours}</span>
                      </li>
                      <li className="flex justify-between">
                        <span>
                          {t("menu")}
                          {selectedLang === "fr" ? " :" : ":"}
                        </span>
                        <span className="font-semibold">
                          {!table.menu?.length
                            ? `${t("menu_choice")}`
                            : table.menu}
                        </span>
                      </li>
                      <li className="flex justify-end mt-2">
                        <button
                          onClick={() => dispatch(removeTable(table.date))}
                          className={`groupe text text-red-700 hover:text-red-800 ${
                            location.pathname.includes("payment") ||
                            location.pathname.includes("personal-information")
                              ? "hidden"
                              : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faTrash} className="mr-1" />
                          {t("delete")}
                        </button>
                      </li>
                    </ul>
                  );
                })}
              </div>
            ) : null}

            <div className="px-5 py-5 text-gray-700 flex flex-col gap-3 border-b">
              <FontAwesomeIcon icon={faGift} className="text-xl" />
              <p className="text-center">{t("gift_voucher_qst")}</p>
            </div>
          </div>
        </div>

        <div className="flex justify-between md:mt-3 md:px-4 text-lg">
          <span className="font-semibold w-1/2 leading-none">
            {t("stay_estimation")}
          </span>
          <span className="font-semibold ">
            {isNaN(totalPrice) ? 0 : totalPrice} €
          </span>
        </div>
        <p className="md:mt-3 md:px-4 font-semibold">
          {t("not_payment_cheque")}
        </p>
      </div>

      {showPopup ? (
        <Popup
          trigger={
            <button
              className={`duration-300 bg-primary rounded px-2 py-1 w-full max-md:text-xl lg:text-xl max-md:py-2 md:w-fit cursor-pointer text-white md:mt-4 self-center  ${
                disableBtn
                  ? "opacity-30 cursor-default pointer-events-none"
                  : "hover:bg-secondary"
              }

          `}
            >
              {t("continue")}
            </button>
          }
          position="right center"
          modal
          contentStyle={{
            backgroundColor: "black",
            border: "transparent",
            borderRadius: "10px",
            color: "white",
          }}
        >
          {(close) => (
            <div className="p-5 md:p-7 flex flex-col gap-5 text-center">
              <div className="text-lg">{t("table_bkg_more")}</div>
              <div className="flex  justify-center gap-3">
                <button
                  className="bg-slate-600 text-white cursor-pointer px-2 rounded hover:bg-white hover:text-secondary duration-300"
                  onClick={close}
                >
                  {t("no")}
                </button>
                <button
                  className="close bg-primary text-white  cursor-pointer rounded px-2 py-1 hover:bg-slate-800 duration-300"
                  onClick={handleContinuePopup}
                >
                  {t("yes")}
                </button>
              </div>
            </div>
          )}
        </Popup>
      ) : (
        <button
          className={`duration-300 bg-primary rounded-md px-3 py-2 w-full max-md:text-lg lg:text-xl md:w-fit cursor-pointer text-white md:mt-4 self-center  ${
            disableBtn
              ? "opacity-30 cursor-default pointer-events-none"
              : "hover:bg-secondary"
          } ${location.pathname.includes("payment") ? "hidden" : ""}
      `}
          onClick={handleContinueBtn}
        >
          {t("continue")}
        </button>
      )}
    </div>
  );
}

export default HotelBasket;
