import React from "react";
import Header from "../Header/Header";
import { Outlet } from "react-router";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";

function AppLayout() {
  return (
    <div className="min-h-screen">
      <Header />
      <NavBar />
      <div className="pb-44 bg-slate-50 min-h-[85vh]">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default AppLayout;
