import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { addOptionsToBasket } from "../../../store/slices/bookingParamSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

function AddOnsItem({ item }) {
  const { code, name_fr, name_en, name_de, unit_price, entire_stay } = item;
  const [openDetails, setOpenDetails] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [flower, setFlower] = useState(true);
  const { startDate } = useSelector((state) => state.bookingParam);
  const selectedLang = useSelector((state) => state.language.selectedLang);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const toastSuccess = () =>
    toast.success(
      `${t(`${quantity > 1 ? "option_toast_plur" : "option_toast_sing"}`, {
        option: `${
          selectedLang === "fr"
            ? name_fr
            : selectedLang === "en"
            ? name_en
            : name_de
        }`,
      })}`
    );

  const handleBtn = () => {
    toastSuccess();
    dispatch(
      addOptionsToBasket({
        quantity,
        unit_price,
        code,
        entire_stay,
        name: name_fr,
        name_fr: name_fr,
        name_en: name_en,
        name_de: name_de,
      })
    );
  };

  useEffect(() => {
    let check_in = new Date(startDate);
    let today = new Date();

    const days = (check_in, today) => {
      let difference = check_in.getTime() - today.getTime();
      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
      return TotalDays;
    };

    if (days(check_in, today) < 2) {
      setFlower(false);
    }
  }, [startDate]);

  return (
    <>
      {item.display_in_list ? (
        <div
          className={`flex flex-col rounded-md border bg-white h-auto justify-between`}
        >
          <div className="">
            <img
              src={item.image}
              alt={item.name_en}
              className="w-full h-[450px] md:h-[370px] rounded-tl-md rounded-tr-md object-cover"
              loading="lazy"
            />
            <p className="font-semibold p-2">
              {" "}
              {selectedLang === "fr"
                ? name_fr
                : selectedLang === "en"
                ? name_en
                : name_de}
            </p>
          </div>
          <div className="flex flex-col p-4 md:max-lg:p-2 gap-2">
            <div className="flex justify-between">
              <div className="flex gap-2 items-center">
                <button
                  disabled={quantity <= 1}
                  onClick={() => setQuantity(quantity - 1)}
                  className="bg-white px-2 border border-gray-800  rounded-full font-medium disabled:text-gray-300 disabled:border-gray-300 hover:bg-slate-700 hover:text-white"
                >
                  -
                </button>
                <span className="text-center">{quantity}</span>
                <button
                  onClick={() => setQuantity(quantity + 1)}
                  className="bg-white px-2 border-gray-800 border rounded-full font-medium hover:bg-slate-700 hover:text-white"
                >
                  +
                </button>
              </div>
              <div className="flex items-center gap-1 md:flex-col-reverse md:gap-0 leading-none md:items-end">
                <span className="text-xs">
                  {item.code === "SOPHRO" || item.code === "232"
                    ? `${t("price_per_pers")}`
                    : `${t("unit_price")}`}
                </span>
                <span className="font-semibold"> €{unit_price}</span>
              </div>
            </div>
            <div className="flex flex-col gap-2 items-center 3xl:flex-row md:justify-between lg:max-xl:flex-col lg:max-xl:items-center">
              <button
                onClick={handleBtn}
                className={`bg-secondary hover:bg-primary rounded text-white w-full py-2 md:w-fit md:py-1 px-2 focus:outline-none focus:ring-1 focus:ring-black ${
                  !flower && code === "FLEURS"
                    ? "bg-gray-300 pointer-events-none"
                    : ""
                }`}
              >
                {t("add_option")}
              </button>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                draggable
              />
              <button
                onClick={() => setOpenDetails((prevState) => !prevState)}
                className="self-end md:self-center flex items-center gap-1 text-sm hover:text hover:text-primary"
              >
                <span> {t("description")}</span>
                {!openDetails ? (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className="hover:translate-y-1 hover:duration-700"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faAngleUp}
                    className="mb-[3px] self-end hover:-translate-y-1 hover:duration-700"
                  />
                )}
              </button>
            </div>
          </div>
          {openDetails && (
            <div
              className="bg-slate-50 mt-3 text-sm p-1 m-2 rounded border "
              dangerouslySetInnerHTML={{
                __html: `${
                  selectedLang === "fr"
                    ? item.description_fr
                    : selectedLang === "en"
                    ? item.description_en
                    : item.description_de
                }`,
              }}
            ></div>
          )}
          {!flower && code === "FLEURS" && (
            <p className="text-red-500 text-center">Indisponible</p>
          )}
        </div>
      ) : null}
    </>
  );
}

export default AddOnsItem;
