import React from "react";
import FormValidation from "../../components/FormValidation";

function PersonalInfo() {
  return (
    <div className="flex flex-col gap-5 bg-slate-50 col-span-2">
      <FormValidation />
    </div>
  );
}

export default PersonalInfo;
