import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedLang: "fr",
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setSelectedLang: (state, action) => {
      state.selectedLang = action.payload;
    },
  },
});

export const languageReducer = languageSlice.reducer;
export const { setSelectedLang } = languageSlice.actions;
