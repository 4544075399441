import React from "react";
import LanguageDropdown from "../LanguageDropdown";
import useFetchData from "../../hooks/useFetchData";

function Header() {
  const { data: companySettings } = useFetchData(`/company/settings`);

  return (
    <div className="flex justify-between items-center gap-4 px-7 min-h-[7vh] md:min-h-[9vh] border-slate-300 border-b bg-black">
      <img src={companySettings.logo} className="w-44 lg:w-56 h-auto" />
      <div className="flex gap-4 text-gray-300 text-lg lg:text-xl pb-2 lg:pb-4 self-end">
        <LanguageDropdown />
      </div>
    </div>
  );
}

export default Header;
