import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";

class PhoneCustomInput extends React.Component {
  render() {
    const {
      input: { value, onChange },
    } = this.props;
    return (
      <PhoneInput
        country={"fr"}
        specialLabel={""}
        value={value}
        onChange={(value) => onChange(value)}
        preferredCountries={["fr", "es", "de", "gb"]}
        className="w-full"
      />
    );
  }
}

export default PhoneCustomInput;
