import React, { useEffect, useState } from "react";
import AvailabilitiesItem from "./AvailabilitiesItem/AvailabilitiesItem";
import { useSelector } from "react-redux";
import axios from "../../api/axios";
import { useNavigate } from "react-router";

function Availabilities() {
  const { startDate, endDate, rooms, roomIndex } = useSelector(
    (state) => state.bookingParam
  );
  const { availabilities } = useSelector((state) => state.availabilities);

  const [disabledRoomArr, setDisabledRoomArr] = useState([]);
  const [guestsData, setGuestsData] = useState([]);
  const [extraPrice, setExtraPrice] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [allRooms, setAllRooms] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (guestsData.length && availabilities.length) {
      getData();
    }
  }, [guestsData, roomIndex, rooms, extraPrice]);

  useEffect(() => {
    let arrRoom = [];
    rooms.map((room) => {
      arrRoom.push({ guests: room.adults });
      room.adults > 2 && setExtraPrice(true);
    });
    setGuestsData(arrRoom);
  }, [rooms]);

  useEffect(() => {
    let arr = [];
    extraPrice &&
      availabilities.map((room) => {
        rooms.forEach((r, i) => {
          if (r.adults > 2 && room.max_capacity > 2 && roomIndex === i) {
            arr.push(room);
          } else if (
            r.adults <= 2 &&
            room.max_capacity <= 2 &&
            roomIndex === i
          ) {
            arr.push(room);
          }
        });
      });
    setFilteredData(arr);
  }, [roomIndex, availabilities, guestsData]);

  useEffect(() => {
    let arr = [];

    allRooms?.forEach((room) => {
      rooms?.forEach((r) => {
        room.quantities.forEach((item) => {
          if (
            item.quantity !== 0 &&
            room.code === r.room_type &&
            rooms.length > 1
          ) {
            return arr.push(...allRooms, {
              ...room,
              quantity: (item.quantity = item.quantity - 1),
            });
          } else if (item.quantity === 0) {
          }
        });
      });
      return allRooms;
    });
  }, [rooms, allRooms, roomIndex, extraPrice]);

  useEffect(() => {
    let disRoomArr = [];
    allRooms?.map((room) => {
      room.quantities.forEach((item) => {
        if (item.quantity === 0) {
          return disRoomArr.push(...disRoomArr, room.code);
        } else {
          disRoomArr = disRoomArr.filter((i) => {
            return i !== room.code;
          });
        }
      });
      return allRooms;
    });
    setDisabledRoomArr(disRoomArr);
  }, [allRooms, roomIndex]);

  const getData = async () => {
    const response = await axios.get(
      `/hotel/availability/rooms-by-period/${startDate}/${endDate}/${JSON.stringify(
        guestsData
      )}/`
    );
    setAllRooms(response.data);
  };

  if (!availabilities.length) return navigate("/hotel");



  return (
    <div className="grid gap-4 2xl:grid-cols-2 grid-rows-none pb-6">
      {extraPrice ? (
        <>
          {filteredData?.map((room, i) => {
            return (
              <AvailabilitiesItem
                room={room}
                key={i}
                rooms={rooms}
                disabledRoomArr={disabledRoomArr}
              />
            );
          })}
        </>
      ) : (
        <>
          {allRooms?.map((room, i) => {
            return (
              <AvailabilitiesItem
                room={room}
                key={i}
                rooms={rooms}
                disabledRoomArr={disabledRoomArr}
              />
            );
          })}
        </>
      )}
    </div>
  );
}

export default Availabilities;
